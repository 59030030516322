<template>
  <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->
   

      <transition name="fade">
        <div v-if="isOpen">
          <div class="overlay" @click.self="">
            <div class="modally z-depth-5 ">
              <div v-show="modalLoading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                <div class="progress">
                  <div class="indeterminate"></div>
                </div>
              </div>
              <div class="padding20">
                <span>
                  <span class="modal-title">{{this.title}}</span>
                  
                </span>
                <span @click.stop.prevent="close()" class="close"><i class="material-icons">close</i></span>
              </div>
              <div v-if="this.action !== 'edit_alias' " class="modal-body row margin0 padding20">
                <div class="col s12">
                  <div class="panellog">


                    <div class="row">
                    <div class="col s8">
                      <div class="input-field" > 
                        <input id="db_host" autocomplete="off" type="text" v-model="database.host" v-on:keyup="change()" />
                        <label for="db_host" v-bind:class="database.host !== '' ? 'active' : '' ">Host/Adres url</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.database.host == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.database.host == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col s4">

                    <div class="input-field"> 
                      <input id="db_port" autocomplete="off" type="text" v-model="database.port" v-on:keyup="change()" />
                      <label for="db_port" v-bind:class="database.port !== '' ? 'active' : '' ">Port</label>
                     <div class="mar- mar-inline-">
                      <span v-if="validation.database.port == true " class="green-text">
                      <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                      </span>
                      <span v-else-if="(validation.database.port == false)" class="red-text">
                        <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                      </span>
                    </div>
                  </div>

                  </div>



                    </div>
                    <div class="input-field mar"> 
                      <input id="db_name" autocomplete="off" type="text" v-model="database.name" v-on:keyup="change()" />
                      <label for="db_name" v-bind:class="database.name !== '' ? 'active' : '' ">Nazwa bazy danych</label>
                      <div class="mar- mar-inline-">
                      <span v-if="validation.database.name == true " class="green-text">
                      <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                      </span>
                      <span v-else-if="(validation.database.name == false)" class="red-text">
                        <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                      </span>
                      </div>
                    </div>

                    <div class="input-field mar"> 
                      <input id="db_user_name" autocomplete="off" type="text" v-model="database.user_name" v-on:keyup="change()" />
                      <label for="db_user_name" v-bind:class="database.user_name !== '' ? 'active' : '' ">Użytkownik</label>
                      <div class="mar- mar-inline-">
                      <span v-if="validation.database.user_name == true " class="green-text">
                      <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                      </span>
                      <span v-else-if="(validation.database.user_name == false)" class="red-text">
                        <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                      </span>
                      </div>
                    </div>

                     <div class="input-field mar"> 
                      <input id="db_user_name" autocomplete="off" type="password" v-model="database.password" v-on:keyup="change()" />
                      <label for="db_password" v-bind:class="database.password !== '' ? 'active' : '' ">Hasło</label>
                      <div class="mar- mar-inline-">
                      <span v-if="validation.database.password == true " class="green-text">
                      <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                      </span>
                      <span v-else-if="(validation.database.password == false)" class="red-text">
                        <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                      </span>
                      </div>
                    </div>

                   
                    <div class="center-align">
                      <transition name="fade">
                      <div v-if="connected" class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                          <div><i class="material-icons  medium green-text text-darken-3">check_circle</i><br>Połączono z bazą danych.</div>
                      </div>
                      <div v-else class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                          <div><i class="material-icons  medium red-text text-darken-3">error</i><br>Wystąpił błąd podczas łączenia z bazą danych.</div>
                      </div>
                      </transition>
                      <transition>
                            <div v-show="connectingLoading">
                                  <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                            <div class="progress">
                              <div class="indeterminate"></div>
                            </div>
                          </div>
                      </transition>
                    </div>
                    <p class="center-align mar-">
                            <button type="button" v-bind:class="this.submitConnecting === true ? '' : 'disabled'" class="btn" v-on:click.prevent="db_test_connection()">Sprawdź poprawność połączenia</button>
                    </p>


                    <div class="input-field mar row">
                      <div class="col s6 center">
                        <div class="switch">
                          <label>
                            Brak odczytu
                            <input type="checkbox" v-bind:disabled="!connected" v-model="database.read_access" >
                            <span class="lever"></span>
                            Odczyt włączony
                          </label>
                        </div>
                      </div>
                      <div class="col s6 center">
                        <div class="switch">
                          <label>
                            Brak zapisu
                            <input type="checkbox" v-bind:disabled="!connected" v-model="database.write_access">
                            <span class="lever"></span>
                            Zapis włączony
                          </label>
                        </div>
                      </div>

                    </div>

              

                     <div v-if="this.action === 'new'" class="center-align mar">
                    <button type="button" v-bind:class="this.validation.connection === true ? '' : 'disabled'" class="btn" v-on:click.prevent="db_create()">Zapisz</button>
                    </div>
                    <div v-else class="center-align mar">
                      
                      <div class="row">
                        <div class="col s6 center-align">
                          <button type="button" v-bind:class="this.validation.connection === true ? '' : 'disabled'" class="btn" v-on:click.prevent="db_update()">Aktualizuj</button><br>
                        </div>
                        <div class="col s6 center-align">
                          <button type="button" class="btn red white-text" v-on:click.prevent="db_delete()"><i class="material-icons red white-text left">delete</i>Usuń bazę</button><br>

                          <div v-if="this.action === 'delete'">
                          
                          <div class="input-field col s12 input-pad button_area_inlne" >
                            <span class="center-align grey-text text-darken-2">Potwierdź usunięcie</span><br>
                            <button @click="close();" class="btn grey left">Anuluj</button>
                            <button @click="db_destroy();" class="btn red right">Usuń</button>
                          </div>

                        </div>

                        </div>
                        <br><br>
                      </div>

                    </div>


                    <p  class="center-align mar">
                        <transition name="fade">
                          <span v-if="this.success" class="green-text ">
                          <i class="material-icons tiny green-text">check_circle</i>{{this.successMessage}}
                          </span>
                        </transition>
                         <transition name="fade">
                          <span v-if="this.error" class="red-text">
                          <i class="material-icons tiny red-text">error</i>{{this.errorMessage}}
                          </span>
                        </transition>
                    </p>


                  </div>
                  
                </div>
              </div>
<!-- ----------------------============================== E_A =============================-------------------------------->


              <div v-if="this.action === 'edit_alias' " class="modal-body row margin0 padding20">
                <div class="col s12">
                  <div class="panellog">

                     <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2">
                <i class="material-icons left orange-text text-lighten-3">info_outline</i>
                Aliasy są tłumaczeniami nazw technicznych, nadawanymi często przez programistów baz danych. Ułatwiają integrację użytkownika z Google AI, po przez powiązania z bazami danych. Np. użytkownik często zostaje zapisany jako <b>user</b>, podczas gdy osoba korzystająca z aplikacji widzi na ekranie nazwę <b>użytkownik</b>.
              </div><br><br>

                    Tabele bazy danych:<br>
                    <div v-for="(table, index) in aliases" :class="['pill', {active: selectedTable ===index}]" :key="index" @click="alias_change_table(index)">
                      {{table.original_name}}
                    </div>                    
                  
                  <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2">
                <i class="material-icons left orange-text text-lighten-3">info_outline</i>
               Nazwy tabeli w bazie danych w znakomitej większości występują w liczbie mnogiej, np. <b> użytkwonicy</b>, <b>produkty</b>, <b>zamówienia</b>
              </div><br><br>

                    <div class="row">
                    <div class="col s6">
                      <div class="input-field" > 
                        <input id="db_host" autocomplete="off" type="text" v-model="aliases[selectedTable].original_name" disabled="disabled" />
                        <label for="db_host" v-bind:class="database.host !== '' ? 'active' : '' ">Nazwa oryginalna tabeli</label>

                    </div>
                  </div>

                  <div class="col s6">
                      <div class="input-field" > 
                        <input id="db_host" autocomplete="off" type="text" v-model="aliases[selectedTable].alias_name" />
                        <label for="db_host" v-bind:class="database.host !== '' ? 'active' : '' ">Alias tabeli</label>

                    </div>
                  </div>


                    </div>



                <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2">
                <i class="material-icons left orange-text text-lighten-3">info_outline</i>
               Nazwy kolumn w bazie danych zazwyczaj występują w liczbie pojedynczej, np. <b>email</b>, <b>Imię</b>, <b>Nazwisko</b>, <b>status</b>, <b>zawód</b>
              </div><br><br>


                    <table>
                      <thead>
                        <tr>
                          <th>Oryginalnia nazwa kolumny</th>
                          <th>Typ kolumny</th>
                          <th>Alias kolumny</th>
                        </tr>
                      </thead>
                      <tbody>
                    <tr v-for="(column, index) in aliases[selectedTable].columns" :key="index" >
                      
                        <td><b>{{column.original_name}}</b></td>
                        <td>{{column.alias_type}}</td>
                        <td>
                          <input id="db_host" autocomplete="off" type="text" v-model="aliases[selectedTable].columns[index].alias_name" />
                        </td>
                    </tr>
                   </tbody>
                   </table>

                    <div class="center-align">
                      <transition>
                            <div v-show="aliasLoading">
                                  <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                            <div class="progress">
                              <div class="indeterminate"></div>
                            </div>
                          </div>
                      </transition>
                    </div>
                    <p class="center-align mar-">
                            <button type="button" v-bind:class="this.submitConnecting === true ? '' : 'disabled'" class="btn" v-on:click.prevent="alias_update()">Aktualizuj aliasy</button>
                    </p>


                    


                  </div>
                  
                </div>
              </div>

              
            </div>
          </div>
        </div>
      
      </transition>
    



     <div class="row">
      <div class="col s8">
        <h1>Bazy danych</h1>
      </div>
      <div class="col s4">
        <div class=" right" style="
    margin: 4.2rem 0 1.68rem 0;">
       <span class="btn " @click="db_new()"><i  class="material-icons left">add</i>Dodaj bazę danych</span>
     </div>
      </div>
      </div>
      <div class=" white pad15">
        
      
      
      <div>
        <div class="panellog">

              <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2">
                <i class="material-icons left orange-text text-lighten-3">info_outline</i>
                Możesz zintegrować chatbota z zewnętrznymi bazami danych, w celu ich przeszukiwania i wyświetlania wyników w chatbocie. Opcjonalnie możesz take ustawić możliwość zapisu danych w bazie.
              </div><br><br>
              <div v-if="databases.length > 0" class="row">
            
                <div v-for="db in databases"
                                     :key="db.id"
                                     class="col s4 ">


                    <div class="card-panel panel-inline db_item grey-text text-darken-2 mar-bot-10 valign-wrapper center-align col s12"><i class="material-icons medium blue-text text-darken-3">dns
                    </i><br><b>{{db.name}}</b>
                      <div v-if="db.read_access">
                        <i class="material-icons green-text tiny">check_circle</i> Odczyt
                      </div>
                      <div v-else>
                        <i class="material-icons grey-text tiny">error</i> Odczyt
                      </div>
                      <div v-if="db.write_access">
                        <i class="material-icons green-text tiny">check_circle</i> Zapis
                      </div>
                      <div v-else>
                        <i class="material-icons grey-text tiny">error</i> Zapis
                      </div>
                    

                       <div class="db_item_overlay">
                         <div class="row margin0">
                          <div class="col s6">
                            <span @click.stop.prevent="db_edit(db.id)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Edytuj">
                              <i class="material-icons">settings</i>
                            </span>
                          </div>
                          <div class="col s6">
                            <span @click.stop.prevent="alias_edit(db.id)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Usuń">
                              <i class="material-icons">view_list</i>
                            </span>
                          </div>
                         </div>
                       </div>

                    </div>


                </div>
              </div>

              <div v-else >
                <div class="center-align">

                        
                        <div  class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                            <div><i class="material-icons  medium grey-text text-darken-1">dns
                        </i><br>Brak powiązanych baz danych</div>
                        </div>

                </div>
              </div>

            </div>
          </div>
        </div>

    </div>
  </div>
</template>


<script>
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js';
import { Navigation } from '../navigation';
 export default {
   name: 'databases',
   data: () => ({
     loading: false,
     modalLoading: false,
     isOpen: false,
     success: false,
     account: null,
     title: '',
     databases: Array,
     aliases: Array,
     selectedTable: null,
     database: { 
              name: '',
              host: '',
              port: null,
              user_name: '',
              password: '',
              read_access: false,
              write_access: false,
              },
     validation: {
                  database: {
                            name: false,
                            host: false,
                            port: true,
                            user_name: false,
                            password: false,
                  },
                  connection: false,

     },
     submitOn: false,
     action: 'index',
     connected: false,
     connectingLoading: false,
     aliasLoading: false,
     submitConnecting: false,
     successMessage: "",
     errorMessage: "",
     success: false,
     error: false,
     //user: null 
   }),


  created: function () {
    if (!localStorage.signedIn) {
      this.$router.replace('/')
    } else {
      //
    }
   
  },

  mounted: function() {
    //this.$emit('accountStatus', this.account.status);
    this.index();
  },
  


   methods: {
     index() {

      this.loading = true;
      securedAxiosInstance.get('/external_databases')
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.databases = response.data.data;
         //this.user = response.data.data.user;
         this.loading = false;
         this.modalLoading = false;
         this.change();
         //if ( this.oldStatus == 'created' && this.company.status == 'completed') {
         //       //redirect to settings for update
         //       console.log('emit clicked')
         //       Navigation.$emit('updateStatusAccount', 'completed');
         //     }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },

     db_new() {
      this.title = "Dodaj nową bazę danych";
      this.action = 'new'
      this.isOpen = true;
      this.success = false;
      this.successMessage = "";
      this.error = false;
      this.errorMessage = "";
      this.validation.connection = false;
      this.connected = false;
     },

     db_create() {
      this.modalLoading = true;
      securedAxiosInstance
              .post('/external_databases', {
                database: this.database,
              })
              .then(response => {
               console.log(response);
               this.modalLoading = false;
               this.close();
               this.index();
               this.success = true;
               this.successMessage = "Zapisano";
               this.error = false;
               this.errorMessage = "";
               //this.fetch();
               //this.initialize();
               //this.updateSequences();
              })
              .catch(error => {
               console.log(error);
               this.modalLoading = false;
               this.success = false;
               this.successMessage = ""
               this.error = true;
               this.errorMessage = "Błąd zapisu";
               //this.fetch();
            });
      
     },

     db_edit(id) {
      this.database = this.databases.find(x => x.id === id);
      this.title = "Edytuj ustawienia bazy " + this.database.name;
      this.db_test_connection();
      this.change();
      this.action = 'edit'
      this.isOpen = true;
      this.success = false;
      this.successMessage = "";
      this.error = false;
      this.errorMessage = "";
     },

    alias_edit(id) {
      this.database = this.databases.find(x => x.id === id);
      this.title = "Edytuj aliasy bazy " + this.database.name;
      //this.db_test_connection();
      this.change();
      this.action = 'edit_alias'
      this.isOpen = true;
      this.success = false;
      this.successMessage = "";
      this.error = false;
      this.errorMessage = "";
      this.loadingModal = true;

      securedAxiosInstance.get('/external_databases/' + id + '/aliases')
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.aliases = response.data.data;
         this.selectedTable = 0;
         //this.user = response.data.data.user;
         this.loadingModal = false;
         this.change();
         //if ( this.oldStatus == 'created' && this.company.status == 'completed') {
         //       //redirect to settings for update
         //       console.log('emit clicked')
         //       Navigation.$emit('updateStatusAccount', 'completed');
         //     }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loadingModal = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });
     },
     alias_change_table(id) {
      this.selectedTable = id;
     },

     alias_update() {
      this.aliasLoading = true;
        securedAxiosInstance
          .put(`/external_databases/${this.database.id}/aliases/update`, {
            database: this.database,
            aliases: this.aliases,
          })
          .then(response => {
           console.log(response);
           this.aliasLoading = false;
           //this.close();
           this.index();
           this.success = true;
           this.successMessage = "Zaktualizowano";
           this.error = false;
           this.errorMessage = "";
           this.validation.connection = true;
           this.connected = true;
           //this.fetch();
           //this.initialize();
           //this.updateSequences();
          })
          .catch(error => {
           console.log(error);
           this.aliasLoading = false;
           this.success = false;
           this.successMessage = ""
           this.error = true;
           this.errorMessage = "Błąd aktualizacji";
           //this.fetch();
        });
     },
     db_update(){
      this.modalLoading = true;
      this.action = 'update';
      securedAxiosInstance
              .put(`/external_databases/${this.database.id}`, {
                database: this.database,
              })
              .then(response => {
               console.log(response);
               this.modalLoading = false;
               //this.close();
               this.index();
               this.success = true;
               this.successMessage = "Zaktualizowano";
               this.error = false;
               this.errorMessage = "";
               this.validation.connection = true;
               this.connected = true;
               //this.fetch();
               //this.initialize();
               //this.updateSequences();
              })
              .catch(error => {
               console.log(error);
               this.modalLoading = false;
               this.success = false;
               this.successMessage = ""
               this.error = true;
               this.errorMessage = "Błąd aktualizacji";
               //this.fetch();
            });
      
     },

     db_delete() {
      this.action = 'delete';
      this.success = false;
      this.successMessage = "";
      this.error = false;
      this.errorMessage = "";
     },

     db_destroy() {
      securedAxiosInstance
              .delete(`/external_databases/${this.database.id}`, {
                database: this.database,
              })
              .then(response => {
               console.log(response);
               this.modalLoading = false;
               //this.close();
               this.index();
               this.success = true;
               this.successMessage = "Usunięto bazę danych";
               this.error = false;
               this.errorMessage = "";
               this.close();
               //this.fetch();
               //this.initialize();
               //this.updateSequences();
              })
              .catch(error => {
               console.log(error);
               this.modalLoading = false;
               this.success = false;
               this.successMessage = ""
               this.error = true;
               this.errorMessage = "Błąd usuwania bazy danych";
               //this.fetch();
            });
     },

     db_test_connection() {
     this.connectingLoading = true;
     securedAxiosInstance
              .post('/external_databases/test_connection', {
                database: this.database,
              })
              .then(response => {
               console.log(response);
               this.connectingLoading = false;
               //this.fetch();
               //this.initialize();
               //this.updateSequences();
                if (response.status === 200) {
                  this.validation.connection = true;
                  this.connected = true;
                  if (this.action === 'new') {
                    this.database.read_access = true;
                    this.database.write_access = true;
                  }
                } else {
                  this.validation.connection = false;
                  this.connected = false;
                }
              })
              .catch(error => {
               console.log(error);
               this.connectingLoading = false;
               //this.fetch();
            });



      
    },

     close() {
      this.isOpen = false;
      this.action = 'index';
      this.success = false;
      this.successMessage = "";
      this.error = false;
      this.errorMessage = "";
      this.database = {};
     },

     open() {
      this.isOpen = true;
     },

     validDBName () {
       if (this.database.name !== null && this.database.name.length > 0) {
        this.validation.database.name = true;
       } else {
        this.validation.database.name = false;
       }
     },

     validDBHost () {
       var format = new RegExp(
        '^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'); // OR ip (v4) address
        //'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        //'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        //'(\\#[-a-z\\d_]*)?$','i'); // fragment locator

       if (this.database.host !== null && format.test(this.database.host)) {
        this.validation.database.host = true;
       } else {
        this.validation.database.host = false;
       }
     },

     validDBPort () {
       var format = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;

       if (format.test(this.database.port) || this.database.port === null || this.database.port === '' ) {
       
        this.validation.database.port = true;
       } else {
        this.validation.database.port = false;
       }
     },

     validDBUserName () {
       if (this.database.user_name !== null && this.database.user_name.length > 0) {
        this.validation.database.user_name = true;
       } else {
        this.validation.database.user_name = false;
       }
     },

     validDBPassword () {
       if (this.database.password !== null && this.database.password.length > 0) {
        this.validation.database.password = true;
       } else {
        this.validation.database.password = false;
       }
     },
     
    checkValidation() {
      if (this.validation.database.name === true
        && this.validation.database.host === true
        && this.validation.database.port === true
        && this.validation.database.user_name === true
        && this.validation.database.password === true
        ) {
        this.submitConnecting = true;
      } else {
        this.submitConnecting = false; 
      }
      console.log(this.submitOn);
    },
    
     change() {
      this.validDBName();
      this.validDBHost();
      this.validDBPort();
      this.validDBUserName();
      this.validDBPassword();

      this.checkValidation();
     
        if (this.action !== 'update') {
          this.validation.connection = false;
          this.connected = false;
        }
      
     },
     
  
     
   },
 };
 //alert('account');

 </script>

 <style scoped>

.modally {
  overflow: hidden;
}
.modal-body {
    overflow: auto !important;
    height: calc(100% - 85px) !important;
}

input[type=text]:not(.browser-default) {
  box-sizing: border-box !important;
}

.input-field .mar  {
  margin-bottom: 12px !important;
}

.panellog {
max-width: 800px;
margin: 0 auto;
  }

.mar-bot-10 {
  margin-bottom:  10px;
}

.db_item {
background-color:  #f5f5f5;
cursor:  pointer;
transition: all 200ms ease;
max-width: 100%;
display: inline-block;
margin: 5px auto;
overflow-wrap: break-word;
position: relative;
  }
.card-panel i {
    margin-right: 0px !important;
}

.db_item:hover {
 background-color:  #e0e0e0;
}


.db_item_overlay {
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(50, 50, 50, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.db_item:hover .db_item_overlay {
  display: flex;
}

.pill {
  border: 2px solid #0277bd;
  display: inline-block;
  transition: .25s;
  color: #0277bd;
  padding: 4px 10px;
  border-radius: 20px;
  margin: 4px;
  cursor: pointer;
}

.pill:hover {
  background-color: #0277bd;
  color: #d9f0ff;
  transition: .25s;
}

.pill.active {
  background-color: #0277bd;
  color: #d9f0ff;
  transition: .25s;
  cursor: default;
}

.input-field {
    margin-bottom: 12px !important;
}
.row {
  margin-bottom: 4px !important;
}
 </style>



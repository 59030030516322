<template>
  <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->
   


     <div class="row">
      <div class="col s12">
        <h1>Konwersacje</h1>
      </div>
      
    </div>
    <div class=" white pad15">
        
      
      
      <div>
        <div class="panellog-">
          <div class="flex-row">
            <div class="flex-column first-col">
 
                <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2">
                  <i class="material-icons left orange-text text-lighten-3">info_outline</i>
                 Tutaj znajdziesz historię konwersacji chatbota umieszczonego na Twojej stronie www.
                </div><br><br>
                <div v-if="conversations.length > 0" class="row">
                  
                  <table class="highlight">
                    <thead>

                    </thead>
                    <tbody>

                        <tr v-for="con in conversations"
                                             :key="con.id"
                                             class="hightlight_row"
                                             @click="show(con.id)"
                                             v-bind:class="con.id === conversation.chat_user.id ? 'grey lighten-2' : ''"
                                             >
                                             
                            <td>
                              <i class="material-icons" v-bind:style="{'color': con.color}">account_circle</i>
                            </td>
                            <td><span v-bind:style="{'color': con.color}">{{con.ip}}</span></td>
                            <td><span v-if="con.last_history" >
                                <div id="assis_app">
                                <div v-if="con.last_history.kind == 'user_sequence_click'"> 
                        
                                    <div class="message-container-user">
                                          <div class="right grey-text text-darken-2 time">{{con.last_history.created_at_time}}</div>
                                          <div class="sequence special selected">
                                           {{con.last_history.message}}     
                                          </div>
                                    </div>
                                  </div>

                                  <div v-if="con.last_history.kind == 'user_input_text'"> 
                                    
                                    <div class="message-container-user">
                                          <div class="right grey-text text-darken-2 time">{{con.last_history.created_at_time}}</div>
                                          <div class="sequence special selected">
                                          {{con.last_history.message}}     
                                          </div>
                                    </div>
                                  </div>

                                  <div v-if="con.last_history.kind == 'user_link_click'"> 
                                    
                                    <div class="message-container-user">
                                          <div class="right grey-text text-darken-2 time">{{con.last_history.created_at_time}}</div>
                                            <div class="message-container-link">
                                              <i class="material-icons grey-text text-darken-2">trending_flat</i><a>{{con.last_history.message.split("|")[1]}}</a>
                                          </div>
                                    </div>
                                  </div>

                                  <div v-if="con.last_history.kind == 'sequence_bot'">
                                    
                                    <div class="message-container-select">
                                      <div class="left grey-text text-darken-2 time">{{con.last_history.created_at_time}}</div><br>
                                      <div class="sequence">
                                        {{con.last_history.message}} 
                                      </div>       
                                    </div>
                                    
                                  </div>

                                  <div v-if="con.last_history.kind == 'message_bot'">
                                    
                                    <div class="message-container">
                                      <div class="left grey-text text-darken-2 time">{{con.last_history.created_at_time}}</div>
                                      <div class="message-hist">
                                         
                                        <div v-html="con.last_history.message"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span></td>
                            <td><span v-if="con.last_history" class="blue-text">{{con.last_history.created_at}}</span></td>
                            
                             
                        </tr>
                      </tbody>
                    </table>
                      

                </div>
                <div id="assis_app">
                  <div class="message-container-buttons">
                    
                 
                    <div v-for="(page, index) in this.pages" @click="index_pagination(page)" class="sequence" v-bind:class="(Number(page) + 1) === currentPage ? 'current' : ''">
                      {{Number(page) + 1}}     
                    </div>
                  </div>
                </div>
                  
                

                  

              </div>
            

              <div class="flex-column">

                     <transition name="fade">
        <div v-if="isOpen">
          <div class="overlay-" @click.self="">
            <div class="modally- z-depth-5- ">
              <div v-show="modalLoading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                <div class="progress">
                  <div class="indeterminate"></div>
                </div>
              </div>
              <div v-show="!modalLoading" class="padding20">
                <span>
                  <span class="modal-title">{{this.title}}</span>


                  
                </span>
               

                <table>
                    <tbody>

                      <tr>
                          <td>
                            <i class="material-icons" v-bind:style="{'color': this.conversation.chat_user.color}">account_circle</i>
                          </td>
                          <td><span v-bind:style="{'color': this.conversation.chat_user.color}">{{conversation.chat_user.ip}}</span></td>
                          <td></td>                         
                      </tr>
                    </tbody>
                </table>
              </div>
              <div id="assis_app" class="modal-body row margin0 padding20">
                
                <div v-if="this.chatbot_logo_url" class="center-align mar10top">
                  <img :src="this.chatbot_logo_url" style="max-width:148px;" />
                </div>
                

                  <div v-for="hist in this.conversation.histories" :key="hist.id">
                    <!-- kind: [:message_bot, :sequence_bot, :conversation_bot, :user_input_text, :user_sequence_click] -->
                      
                      <div v-if="hist.kind == 'user_sequence_click'"> 
                        
                        <div class="message-container-user">
                              <div class="right grey-text text-darken-2 time">{{hist.created_at_time}}</div>
                              <div class="sequence special selected">
                               {{hist.message}}     
                              </div>
                        </div>
                      </div>

                      <div v-if="hist.kind == 'user_input_text'"> 
                        
                        <div class="message-container-user">
                              <div class="right grey-text text-darken-2 time">{{hist.created_at_time}}</div>
                              <div class="sequence special selected">
                              {{hist.message}}     
                              </div>
                        </div>
                      </div>

                      <div v-if="hist.kind == 'user_link_click'"> 
                        
                        <div class="message-container-user">
                              <div class="right grey-text text-darken-2 time">{{hist.created_at_time}}</div>
                                <div class="message-container-link">
                                  <div class="sequence special selected">
                                  {{hist.message.split("|")[0]}}     
                                  </div><i class="material-icons grey-text text-darken-2">trending_flat</i><a v-bind:href='hist.message.split("|")[1]'>{{hist.message.split("|")[1]}}</a>
                              </div>
                        </div>
                      </div>

                      <div v-if="hist.kind == 'sequence_bot'">
                        
                        <div class="message-container-select">
                          <div class="left grey-text text-darken-2 time">{{hist.created_at_time}}</div><br>
                          <div class="sequence">
                            {{hist.message}} 
                          </div>       
                        </div>
                        
                      </div>

                      <div v-if="hist.kind == 'message_bot'">
                        
                        <div class="message-container">
                          <div class="left grey-text text-darken-2 time">{{hist.created_at_time}}</div>
                          <div class="message-hist">
                             
                            <div v-html="hist.message"></div>
                          </div>
                        </div>
                      </div>


                  </div>


              </div>
            </div>
          </div>
        </div>

      </transition>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js';
import { Navigation } from '../navigation';
 export default {
   name: 'conversations',
   data: () => ({
     loading: false,
     modalLoading: false,
     isOpen: false,
     success: false,
     account: null,
     title: 'Historia konwersacji',
     conversations: Array,
     conversation: Object,
     pagination: Object,
     action: 'index',
     chatbot_logo_url: null,
     pages: Array,
     currentPage: 1,
     //user: null 
   }),


  created: function () {
    if (!localStorage.signedIn) {
      this.$router.replace('/')
    } else {
      //
    }
   
  },

  mounted: function() {
    //this.$emit('accountStatus', this.account.status);
    this.index();    
  },
  


   methods: {
     index() {

      this.loading = true;
      securedAxiosInstance.get('/conversations')
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.conversations = response.data.data.conversations;
         this.conversation = response.data.data.conversation;
         this.pagination = response.data.data.pagination;
         this.chatbot_logo_url = response.data.data.chatbot_logo_url;
         //this.user = response.data.data.user;
         
         //this.change();
         this.pages = Object.keys(this.pagination.pages);
         this.loading = false;
         console.log('paginacja');
         console.log(this.pagination.pages);
         console.log(Object.keys(this.pagination.pages));

         //if ( this.oldStatus == 'created' && this.company.status == 'completed') {
         //       //redirect to settings for update
         //       console.log('emit clicked')
         //       Navigation.$emit('updateStatusAccount', 'completed');
         //     }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },

     index_pagination(page) {

      this.loading = true;
      securedAxiosInstance.get('/conversations', {params: {page: page}}) 
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.conversations = response.data.data.conversations;
         //this.conversation = response.data.data.conversation;
         
         this.chatbot_logo_url = response.data.data.chatbot_logo_url;
         //this.user = response.data.data.user;
         this.pagination = response.data.data.pagination;
         this.pages = Object.keys(this.pagination.pages);
         
         this.currentPage = Number(this.pagination.page);
         this.loading = false;
         //this.change();
         //if ( this.oldStatus == 'created' && this.company.status == 'completed') {
         //       //redirect to settings for update
         //       console.log('emit clicked')
         //       Navigation.$emit('updateStatusAccount', 'completed');
         //     }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },

     show(chat_user_id) {
      console.log(chat_user_id);
      this.open();

      this.modalLoading = true;
      securedAxiosInstance.get(`/conversations/${chat_user_id}`)
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.conversation = response.data.data;

         //this.pagination = response.data.data.pagination;
         //this.user = response.data.data.user;
         this.modalLoading = false;
         this.change();
         //if ( this.oldStatus == 'created' && this.company.status == 'completed') {
         //       //redirect to settings for update
         //       console.log('emit clicked')
         //       Navigation.$emit('updateStatusAccount', 'completed');
         //     }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },

     close() {
      this.isOpen = false;
      this.action = 'index';
     },

     open() {
      this.isOpen = true;
      this.action = 'show';
     },

     
  
     
   },
 };
 //alert('account');

 </script>

 <style scoped>

.modally {
  overflow: hidden;
}
.modal-body {
    overflow: auto !important;
    height: calc(100% - 85px - 80px) !important
}

input[type=text]:not(.browser-default) {
  box-sizing: border-box !important;
}

.input-field .mar  {
  margin-bottom: 12px !important;
}

.panellog {
max-width: 800px;
margin: 0 auto;
  }

.mar-bot-10 {
  margin-bottom:  10px;
}

.db_item {
background-color:  #f5f5f5;
cursor:  pointer;
transition: all 200ms ease;
  }

.db_item:hover {
 background-color:  #e0e0e0;
}


.hightlight_row {
transition: all 300ms ease-in-out;
cursor: pointer;
}

#assis_app .hightlight_row:hover {
background-color: #f5f5f5 !important;
}



td {padding:  5px !important;}

.message-container-user {
  
}

#assis_app .message-container {
    margin: 0 auto;
    font-size: 14px;
    padding: 0px 16px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start
}

#assis_app .message-container-user {
    margin: 0 auto;
    font-size: 14px;
    padding: 0px 16px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end
}

#assis_app .message-container-select {
    margin: 0 auto;
    font-size: 14px;
    padding: 0px !important;
    padding-top: 0px;
    display: flex;

    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: flex-start;
}

#assis_app .message-container-buttons {
    
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: flex-start;
}


 #assis_app .sequence {
  border-radius: 20px;
  padding: 10px;
  color: #0277bd;
  float:left;
  //clear:left;
  margin: 4px;
  margin-top: 0px;
  //position:relative;
  transition: all .3s ease;
  text-align:left;
  //min-width:100px;
  //background-color: #0277bd;
  cursor:  pointer;
  border: 2px solid #0277bd;
  transition:  all 0.2 ease-in-out;

}

#assis_app .sequence:hover {
  background-color: #0d47a1;
  color: white;
  //background: rgb(9,31,121);
  background: linear-gradient(48deg, #0277bd, 35%, #0d47a1 100%);
  transition:  all 0.2 ease-in-out;
}

#assis_app .selected {
  background-color: #0d47a1;
  background: rgb(9,31,121);
    //background: linear-gradient(48deg, rgba(9,31,121,1) 35%, #0d47a1 100%);
    background: linear-gradient(48deg, #0277bd, 35%, #0d47a1 100%);
    color: white;
    border: 2px solid #0277bd;
    float: right;
    clear:both;
    margin-top: 4px;
    cursor:  default;
}

#assis_app .selected.special > .material-icons {
  color:  #39b5ff;
}

#assis_app .new_line {
  clear: both;
}

#assis_app .special > .material-icons {
  font-size:  16px;
  clear: left;
}

#assis_app .special:hover > .material-icons {
  color:  #39b5ff;
}

#assis_app .message-container {
  margin: 0 auto;
  //display:flex;
  //flex-direction: column;
  //justify-content: safe left;
  font-size: 14px;
  padding: 0;

}

#assis_app .hide {
 //color:  red;
 //display: none;
}

#assis_app .yellow {
  //color:  gold !important;
}


#assis_app .left {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

#assis_app .time {
  font-size: 12px;
}

#assis_app .message a {
  color: white !important;
  text-decoration: underline !important;
}

#assis_app .message-container-link {
  //margin: 0 auto;
    font-size: 14px;
    padding: 0;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

#assis_app .message-hist {
  border-radius: 20px;
  padding: 12px 16px;
  color: black;
  float:left;
  clear:both;
  margin: 6px 4px;
  margin-top: 0px;
  //position:relative;
  //transition: all .3s ease;
  text-align:left;
  //min-width:100px;
  background-color: rgb(240, 238, 244);
  color: rgb(24, 27, 32);
  //min-width: 100px;
  max-width: 220px;
  //width: 85%;
  font-size: 14px;
  
}

.message-hist div p {
  margin: 0 !important;
}

.message-hist a {
border-radius: 20px;
    padding: 10px;
    color: #0277bd;
    float: left;
    //clear: left;
    margin: 4px;
    margin-top: 0px;
    //position: relative;
    transition: all .3s ease;
    text-align: left;
    //min-width: 100px;
    background-color: white !important;
    cursor: pointer;
    border: 2px solid #0277bd;
    transition: all 0.2 ease-in-out;
    margin: 5px 0px;
    text-decoration: underline;
}

.message-hist a:hover {
    background-color: #0d47a1;
    color: white;
    //background: rgb(9,31,121);
    background: linear-gradient(48deg, #0277bd, 35%, #0d47a1 100%);
    transition: all 0.2 ease-in-out;
    text-decoration: none;
}

.flex-row {
  display: flex;
      display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    height: calc(100vh - 120px) !important;
    //overflow-y: auto;
}
.flex-column {
  height: calc(100vh - 120px) !important;
  overflow-y: hidden !important;
}

.flex-column:hover {
  overflow-y: auto !important;
}

.first-col {width: 65%;}

.selected_row {
    background-color: #0277bd;
    color: #d9f0ff !important;
  }

.current {
  background-color: #0277bd;
  color: #d9f0ff !important;
  cursor:  default;
}
 </style>












<template>
  <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->
      <LayModal ></LayModal>
      <SequenceModal v-on:close-modal="updateOpenProp" 
                :openProp="open_modal"
                v-bind:prop-active-sequence="this.sequence"
                @fetchSequencesTree="fetchSequencesAgain($emit)"
                :key="this.key">
      </SequenceModal>

     <div class="row">
      <div class="col s4">
        <h1>Sekwencje</h1>
      </div>
      <div class="col s8">
        <div class=" right" style="
    margin: 4.2rem 0 1.68rem 0;">
       <span class="btn " @click="emitShowItems();"><i  class="material-icons left">expand_more</i>Rozwiń wszystko</span>
       <span class="btn " @click="emitHideItems();"><i  class="material-icons left">expand_less</i>Zwiń wszystko</span>
     </div>
      </div>
     </div>
     
      <div id="moveable" ref="moveable" v-bind="movable_attrs" class="diagram white" >
        <div id="tree" class="tree">
          
              <ul>
                <subsequence :node="sequences" :sequence-show="openModalShow" ></subsequence>
              </ul>

        </div>
      </div>
      
       <!-- request finished -->

    </div>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.js';
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js'
import { EventBus } from '../eventbus';
import subsequence from "./subsequence.vue";
import LayModal from "./layouts/LayModal.vue"
import SequenceModal from "./layouts/SequenceModal.vue"
 export default {
   name: 'sequences',
   props: {
    treeData: Object
    },
    components: {
      subsequence,
      LayModal,
      SequenceModal,
    },
   data: () => ({
     tree: '',
     sequences: Object,
     sequence: Object,
     test:  {
        label: "A cool folder",
        children: [
        {
          label: "A cool sub-folder 1",
          children: [
            { label: "A cool sub-sub-folder 1" },
            { label: "A cool sub-sub-folder 2" }
          ]
        },
        { label: "This one is not that cool" }
      ]
    },
    loading: true,
    open_modal: false,
    centering: false,
    movable_attrs: {
      scrollTop: 140,
      scrollLeft: 200,
      style: {
        backgroundColor: 'red',
      }      
    },
    key: null,
   }),

   created: function () {
    if (!localStorage.signedIn) {
      this.$router.replace('/')
    } else {
      this.fetchSequences();
    }
   },
   mounted() {
      this.eventBusOpenModalShow();
      this.getMoveable();
      this.open_modal = false;
      this.key = null;
      this.sequence = null;
  },

   methods: {
     fetchSequences() {
        // ...
         securedAxiosInstance.get('/sequences')
          .then(response => {  
          this.sequences = response.data.data.tree;
          this.loading = false;  
          }) // code to run on success
          .catch(error => { this.sequences = null }) // code to run on error
          //.then(() => (); // set loading to false when request finish
          //this.centChart();
        //  this.open_modal = true;
     },
     getSequence(item_id) {
      securedAxiosInstance.get(`/sequences/${item_id}`)
       .then(response => {
         this.sequence = response.data.data;
          this.open_modal = true;
        })
       .catch(error => {
        console.log(error);
        });
       console.log(item_id);

    },
    openModalShow(item_id) {
        if (off_clickable === false) {
          console.log('OMS');
          console.log(item_id);
          this.getSequence(item_id);
        console.log(this.sequence)
        }
      },
   
   updateOpenProp(value) {
    console.log('updateParentProp');
    console.log(value);
     this.open_modal = value;
   },
   fetchSequencesAgain() {
    //this.loading = true;
    this.fetchSequences();
   },
   getMoveable()  {
          console.log('ele');    
    var ele = this.$el.querySelector("#moveable");
    //var tree = this.$el.querySelector("#tree");
    //var tree_pos = tree;
      //ele.scrollTop = 400;
    console.log(ele);
    //ele.scrollLeft =  (ele.offsetWidth / 2 + 100) ;
    ele.addEventListener("mousedown", mouseDownHandler);
    
   },
   centeringChart2() {
        //if (centering === false) {
      var ele = document.getElementById("moveable");
      ele.scrollLeft =  (ele.offsetWidth / 2 + 100) ;
      centering = true;
      console.log('chart2');
      //console.log(ele);
      //ele.scrollLeft = 10;
       //}
   },

   

   eventBusOpenModalShow() {
        
        EventBus.$on('sequence-show', (item_id) => {
           //this.key = null;
           console.log(item_id);
           let seqenece_id = item_id;
           
           //this.key = item_id;
           this.openModalShow(item_id);
           this.sequence.id = item_id;
           this.key = item_id;
        });

        EventBus.$on('sequence-show-upd', (item_id) => {
           console.log(item_id);
           //let seqenece_id = item_id;
           //this.sequence.id = null;
           //this.key = null;
           //this.key = null;
           //this.openModalShow(item_id);
           if (item_id != this.key) {
            this.openModalShow(item_id);
             this.sequence.id = item_id;
             this.key = item_id;
           }
        });

      
   },

   emitShowItems(){
    EventBus.$emit('show-items', true);
    //this.centeringChart2();
    this.centeringChart();
   },
   emitHideItems(){
    EventBus.$emit('show-items', false);
   },
   

 },
};
 //alert('account');

let pos = { top: 0, left: 0, x: 0, y: 0 };
let off_clickable = false;
let centering = false;

const centeringChart = function () {
    if (centering === false) {
      var ele = document.getElementById("moveable");
      ele.scrollLeft =  (ele.offsetWidth / 2 + 100) ;
      centering = true;
      console.log('eloo');
      console.log(ele);
      ele.scrollLeft = 10;
    }
   
}

const mouseDownHandler = function (e) {
    var ele = document.getElementById("moveable");
    pos = {
        // The current scroll
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
            // Change the cursor and prevent user from selecting the text

    };

      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
      off_clickable = true;
      console.log('down');

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
};

const mouseMoveHandler = function (e) {
    var ele = document.getElementById("moveable");
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
    off_clickable = true;
    console.log('move');
};


const mouseUpHandler = function () {
    var ele = document.getElementById("moveable");

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
    off_clickable = false;
    console.log('up');
};

 </script>

 <style>
 .diagram {
  overflow: auto;
 }

  .diagram .tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}
.diagram .tree ul::after {
  content: '';
  display: table;
  clear: both;
}
.diagram .tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 1em 0.5em 0 0.5em;

}
.diagram .tree li::before, .diagram .tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 50%;
  height: 1em;

}
.diagram .tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}
.diagram .tree li:only-child::after, .diagram .tree li:only-child::before {
  display: none;
}
.diagram .tree li:only-child {
  padding-top: 0;
}
.diagram .tree li:first-child::before, .diagram .tree li:last-child::after {
  border: 0 none;
}
.diagram .tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
}
.diagram .tree li:first-child::after {
  border-radius: 5px 0 0 0;
}
.diagram .tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 1em;
}
.diagram .tree li a {
  border: 2px solid #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  //color: #333;
  position: relative;
  top: 1px;
  cursor: pointer;
  white-space: normal;
    max-width: 300px;
    width: 100%;
  //transition: all ease-in-out 100ms;
  background-color: #0277bd;
  color:white;
}
.diagram .tree li a:hover, .diagram .tree li a:hover + ul li a {
  background: #0d47a1;
  //color: #fff;
  //border: 2px solid #90caf9;
}
.diagram .tree li a:hover + ul li::after, .diagram .tree li a:hover + ul li::before, .diagram .tree li a:hover + ul::before, .diagram .tree li a:hover + ul ul::before {
  border-color: #e9453f;
}


  [draggable=true] {
  //cursor: move;
}
 .message {
  white-space: normal !important;
}

#moveable {
  cursor: grab;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 2em - 240px);
}

.c_container {
  margin: 0 auto;
  display:  block;
}

.c_btn {
  width: 24px;
  height:24px;
  text-align: center;
  border-radius:50%;
  //background-color: gray;
  display: block;
  border: 2px solid #ccc;
  transition: all 300ms ease-in ;
  cursor: pointer;
  //float: right;
  margin:  0 auto;
}

.c_btn .material-icons {
//margin-top: 3px;
font-size: 20px;
}

.c_btn:hover {
  background-color: #ccc;  
}

.pad15 {padding: 15px;}


.message strong {
    font-weight: bolder !important;
}

.message p {
  margin: 0 !important;
}

.message ul {
    list-style-type: disc !important;
    padding-left:  40px !important;
}

.message ul > li {
   list-style-type: none !important;
   display: list-item;
}

.message .ql-align-center {
  text-align: center !important;
}

.message .ql-align-left {
  text-align: left !important;
}

.message .ql-align-right {
  text-align: right !important;
}

.message .ql-align-justify {
  text-align: justify !important;
}


.message ol li,  .message ul li {
    padding-left: 0 !important;
    }

.message .ql-indent-1 {
    padding-left: 3em !important;
}
.message .ql-indent-2 {
    padding-left: 6em !important;
}
.message .ql-indent-3 {
    padding-left: 9em !important;
}
.message .ql-indent-4 {
    padding-left: 12em !important;
}

.message ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }


.message ol > li:before {
    content: counter(list-0, decimal) '. '!important;
    display: inline !important;
}

.message ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ' !important;
    display: inline !important;
}

.message li::before {
    margin-left: -1.5em !important;
    margin-right: 0.3em !important;
    text-align: right !important;
}

.message ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
}
.message ol li.ql-indent-1 {
    counter-increment: list-1 !important;
}

.message ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
}
.message ol li.ql-indent-2 {
    counter-increment: list-2 !important;
}

.message ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. '!important;
    display: inline !important;
}

.message ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9 !important;
}

.message ol li.ql-indent-3 {
    counter-increment: list-3 !important;
}

.message ol > li, ul > li {
    list-style-type: none !important;
}

.message ul > li::before {
    content: '\2022' !important;
    display: inline !important;
}

.message li::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
}

.message  ol li, .ql-editor ul li {
    padding-left: 0em;
}

.message  ul > li::before {
    content: '\2022' !important;
    display: inline !important;
}

.message  ul::before {
    display: none !important;
}

.diagram .tree .message li {
    //display: inline-block;
    vertical-align: top;
    text-align: left;
    list-style-type: none;
    position: relative;
    padding: 0;
}

.diagram .tree .message li::before {
  display: none;
}

.diagram .tree .message li::after {
  display: none;
}

p > ol > li::before, p > ol > li::after {
  display: none !important;
}

p > ul > li::before, p > ul > li::after {
  display: none !important;
}

.diagram .tree .message li {
    display: block !important;
}
.diagram .tree .message li::before {
    position: static;
    border: none;
    width: auto;
    height: auto;
}

.diagram .tree .message li {
  text-align: left;
}

.diagram .tree .message li:last-child::before {
    border: none;
  }
 </style>


<template>
    <div v-if="this.parSeq.parent !== null">
      <parSeq :parSeq="this.parSeq.parent"></parSeq>
      <div>
        <div class="parent_sequence sequence_hov" @click.stop.prevent="emitGlobalChangeViewToParent">{{this.parSeq.parent.name}}</div>
      </div>
      <div class="vertical_line_cont">
        <div class="vertical_line"></div>
      </div>
    </div>
</template>

<script>
import LayModal from "./layouts/LayModal.vue"
import { EventBus } from '../eventbus';

export default {
  name: "parSeq",
  props: {
    parSeq: Object
  },
  components: {
      LayModal,
  },
  methods: {
    emitGlobalChangeViewToParent() {
      //$event.$emit('sequence-show', parSeq.id)
      EventBus.$emit('change-view-to-parent', this.parSeq.parent_id);
      console.log('emitted');
    }
  }
  
};
 //@click="$parent.openModalShow(parSeq.id)" 
</script>
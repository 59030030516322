<template>

   <article class="main grey lighten-3 ">
      <div :is="activeView"></div>
    </article>
</template>

<script>
import LaySidebar from './LaySidebar.vue'
import start from '../start.vue'
import sequences from '../sequences.vue'
import settings from '../settings.vue'
import databases from '../databases.vue'
import conversations from '../conversations.vue'
import YourAccount from '../YourAccount.vue'
import { Navigation } from '../../navigation';
//import NavigationLink from '../NavigationLink.vue'

 export default {
   name: 'LayMain',
   props: {
    message: String,
   }, 
   data () {
    return {
      activeView: 'sequences'
    };
  
  },
mounted() {
  Navigation.$on('clicked', (template) => {
     this.activeView = template;
  });
},
  components: {
   LaySidebar,
   start,
   sequences,
   YourAccount,
   settings,
   databases,
   conversations,
  }
};

//const changeView = function(activeView) {
//  console.log(`The button has been clicked ${activeView}!`)
//  console.log(activeView)
//  this.activeView = activeView

//}

//Navigation.$on('clicked', setView);

</script>

<style>
body {overflow: hidden;}

</style>
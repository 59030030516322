<template>
    <div id="login" class="z-depth-2" v-on:keyup.enter="signin()">
        <div class="panellog">
          <div class="light-blue  darken-4 white-text headlog center-align"><h4> Zaloguj się</h4></div>
          <div v-show="loading">
            <div class="progress margin0">
              <div class="indeterminate"></div>
            </div>
          </div>
          <div class="input-field mar">
            <input v-model="email" type="email" id="email" />
            <label for="email" class="">Email</label>
          </div>
          <div class="input-field mar">
              <input id="password" autocomplete="off" type="password" v-model="password" />
              <label for="password_id" class="">Hasło</label>
          </div>
          <div class="center-align">
            <transition name='fade'>
              
              <span class="red-text" v-if="error">{{ errorsDesc[error] }}</span>
              }
            </transition>
          </div>
          <p class="center-align mar-">
                  <button type="button" class="btn" v-on:click="signin()">Zaloguj się</button>
          </p>

          <div class="center-align mar">
            <div class="grey-text text-darken-2"> Nie pamiętasz hasła?</div>
            <router-link to="/reset-hasla">Zmień hasło</router-link><br><br>

            <div class="grey-text text-darken-2"> Nie masz jeszcze konta?</div>
            <router-link to="/zaloz-konto">Zarejestruj się</router-link>
          </div>

      </div>
    </div>

</template>

<script>
import { plainAxiosInstance } from '../backend/axios/index.js'
export default {
  name: 'Signin',
  data () {
    return {
      loading: false,
      email: '',
      password: '',
      error: '',
      errorsDesc: {
        401: 'Błędny login lub hasło',
        404: 'Użytkownik o podanym adresie email nie istnieje',
      },
    }
  },
  created () {
    this.checkSignedIn()
  },
  updated () {
    this.checkSignedIn()
  },
  methods: {
    signin () {
      this.loading = true;
      plainAxiosInstance.post('/signin', { email: this.email, password: this.password })
        .then(response => this.signinSuccessful(response))
        .catch(error => this.signinFailed(error))
      
    },
    signinSuccessful (response) {
      if (!response.data.csrf) {
        this.signinFailed(response)
        return
      }
      localStorage.csrf = response.data.csrf
      localStorage.signedIn = true
      //this.$emit("authenticated", true);
      this.error = ''
      this.$router.replace('/secure')
      this.loading = false;
    },
    signinFailed (error) {
      //this.error = (error.response && error.response.data && error.response.data.error) || ''
      this.error = error.response.status;
      delete localStorage.csrf
      delete localStorage.signedIn
      //this.$emit("authenticated", false);
      this.loading = false;
      
    },
    checkSignedIn () {
      //if (localStorage.signedIn) {
      //  this.$router.replace('/secure')
      //}
      delete localStorage.csrf
      delete localStorage.signedIn
    }
  }
}
</script>

<style scoped>
    #login {
        width: 500px;
        //border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
         position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
    }
    .panellog {
      margin:0;
      overflow-y: auto;
    max-height: 90vh;
    }
    .mar {
      margin: 40px;
    }

    .mar input {
      width:100%;
      box-sizing: border-box;
    }
    .headlog {
    
    }

    h4 {
      margin: 0;
      padding:10px;
    }

    .margin0 {margin: 0 !important;}
</style>
<template>
   <div>
      <transition name="fade">
        <div v-if="isOpen">
          <div class="overlay" @click.self="isOpen = false;">
            <div class="modally z-depth-5 ">
              <div class="padding20">
                <span>
                  <span class="modal-title">Sekwencja</span>
                  <span v-if="!this.editSequence" class="sequence-active">
                     {{activeSequence.name}}
                  </span>
                  <span v-if="!this.editSequence && !this.starting" @click.stop.prevent="editSequenceName()" class="message-btn- close inline-btn">
                    <i class="material-icons">edit</i>
                  </span>
                  <span v-else-if="this.editSequence && !this.starting">
                    
                    <div class="input-field inline input-field-margin0">
                      <input v-model="activeSequence.name" id="sequence_name" type="text" class="input-sequence-name" autocomplete="off">
                    </div>
                    <span @click.stop.prevent="cancelSequenceName()" class=" close inline-btn">
                      <i class="material-icons">close</i>
                    </span>
                    <span @click.stop.prevent="saveSequenceName()" class=" close inline-btn">
                      <i class="material-icons">check</i>
                    </span>
                  </span>
                  <span v-else-if="this.starting">
                    <span  class="close inline-btn disabled">
                      <i class="material-icons">edit</i>
                    </span>
                    <div class="card-panel panel-inline card-panel-up amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Nazwy tej sekwencji nie można zmienić</div>
                  </span>
                </span>
                <span @click.stop.prevent="close" class="close"><i class="material-icons">close</i></span>
              </div>
              <div class="row margin0">
                <div class="col s3 grey lighten-2 height-modal-item">
                  <div class="center-align pad-8 "><span class="grey-text text-darken-2"><b>Tutaj jesteś</b></span></div>
                  
                  <div v-if="this.activeSequence.parent !== null">
                    <parSeq :parSeq="this.activeSequence"
                            :change-view-to-parent="changeActiveSubsequenceToParent"
                    ></parSeq>
                  </div>

                  <div v-else class="center-vh-">
                    <div class="center-align">
                          <div class="card-panel panel-inline card-panel-up amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Ta sekwencja nie posiada sekwencji nadrzędnych.</div>
                        </div>
                  </div>

                  <div class="center-align">
                      <div class="sequence-active sequence-active-panel">{{this.activeSequence.name}}</div>
                  </div>

                  <div v-if="activeSequence.subsequences.length">
                    <div class="vertical_line_cont">
                      <div class="vertical_line"></div>
                    </div>
                    <div v-for="subseq in activeSequence.subsequences"
                      :key="subseq.id"  class="sequence-container"
                    >  <div class="vertical_line_cont">
                          <div class="vertical_line_short"></div>
                       </div>
                      <div class="parent_sequence sequence_hov" @click="changeActiveSubsequenceToChild(subseq.id)">{{subseq.name}}</div>
                    </div>
                  </div>
                  <div v-else class="center-vh-">
                    <div class="center-align">
                      <br>
                          <span class="grey-text text-darken-2"> Brak sekwencji podrzędnych</span><br>
                          <i class="large material-icons grey-text">view_stream</i>
                        </div>
                  </div>
                </div>
                <div class="col s4 blue- height-modal-item scrollable" >
                  <div class="message-container grey- lighten-2- center-align">
                    <span v-if="this.activeSequence.parent" class="parent_sequence">
                     {{activeSequence.parent.name}}
                    </span>
                    <div v-if="this.activeSequence.parent" class="vertical_line_cont">
                      <div class="vertical_line"></div>
                    </div>
                    <span class="sequence-active sequence-active-panel">
                     {{activeSequence.name}}
                    </span>
                    <div v-if="activeSequence.items.length" class="container_border" >
                      <div >
                        <div class="center-align grey-text text-darken-2">Wiadomości ({{this.activeSequence.items.length}})</div>
                        <div v-for="item in activeSequence.items"
                             :key="item.id" 
                             v-bind:class="[ (editedIndex === item.id ? 'active' : ''), (deletedIndex === item.id ? 'to_delete' : '') ]"
                             class='message grey lighten-4'>{{item.content}}
                             <div class="message-overlay">
                               <div class="row margin0">
                                <div class="col s6">
                                  <span @click.stop.prevent="editItem(item)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Edytuj">
                                    <i class="material-icons">edit</i>
                                  </span>
                                </div>
                                <div class="col s6">
                                  <span @click.stop.prevent="deleteItem(item)" class="message-btn delete tooltipped" data-position="bottom" data-tooltip="Usuń">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </div>
                               </div>
                             </div>
                        </div>
                        <div class="button_area_inlne">
                           <a @click.stop.prevent="newItem();" class="btn-floating btn-large- waves-effect waves-light"
                           v-bind:class="{'pulse' : !this.activeSequence.items.length }">
                            <i class="material-icons">add</i></a>
                        </div>
                      </div>
                    </div>
                    <div v-else class="center-vh-">
                        <div class="container_border">
                          <span class="grey-text text-darken-2"> Brak wiadomości</span><br>
                          <i class="large material-icons grey-text text-lighten-2">chat</i>
                          <div class="button_area_inlne">
                           <a @click.stop.prevent="newItem();" class="btn-floating btn-large- waves-effect waves-light"
                           v-bind:class="{'pulse' : !this.activeSequence.items.length }">
                            <i class="material-icons">add</i></a>
                        </div>
                        </div>
                    </div>



                    <div class="vertical_line_cont">
                      <div class="vertical_line"></div>
                    </div>
                    <div>

                   
                      <div v-if="activeSequence.subsequences.length" class="container_border">
                         <div class="center-align grey-text text-darken-2">Sekwencje ({{this.activeSequence.subsequences.length}})</div>
                        <div v-for="subseq in activeSequence.subsequences"
                          :key="subseq.id" class="sequence-container "
                        >
                          <div class="sequence">{{subseq.name}}
                            <div class="sequence-overlay">
                               <div class="row margin0">
                                <div class="col s6">
                                  <span @click.stop.prevent="editSubsequence(subseq)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Edytuj">
                                    <i class="material-icons">edit</i>
                                  </span>
                                </div>
                                <div class="col s6">
                                  <span @click.stop.prevent="deleteSubsequence(subseq)" class="message-btn delete tooltipped" data-position="bottom" data-tooltip="Usuń">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </div>
                               </div>
                             </div>

                          </div>

                        </div>
                      </div>
                      <div v-else class="center-vh- container_border">
                        <div class="center-align">
                              <span class="grey-text text-darken-2"> Brak sekwencji podrzędnych</span><br>
                              <i class="large material-icons grey-text text-lighten-2">view_stream</i>
                            </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col lighten-2 s4 center-vh " v-bind:class="{ ' grey-' : this.editedIndex > -1}">
                    <div v-if="this.action==='message'">
                      <div v-if="(this.newMessage && !this.confirmDestroy) || (this.editedIndex > -1 && !this.confirmDestroy)">
                       <h5 class="center-align">{{formTitle}} wiadomość</h5>
                       <div class="input-field col s12 pad30" >
                          <textarea v-model="editedItem.content" id="textarea1" class="materialize-textarea" autocomplete="off"></textarea>
                          <button @click="cancel();" class="btn grey">Anuluj</button>
                          <button @click="save(editedItem);" class="btn right">Zapisz</button>
                        </div>
                    </div>
                    <div class="center align" v-else-if="!this.confirmDestroy">
                      <span class="grey-text text-darken-2">Dodaj wiadomość</span>
                      <div>
                       <a @click.stop.prevent="newItem();" class="btn-floating btn-large waves-effect waves-light center-plus-btn"
                       v-bind:class="{'pulse' : !this.activeSequence.items.length }">
                        <i class="material-icons">add</i></a>
                      </div>
                    </div>   

                      <div v-else>
                        <h5 class="center-align">Usuń wiadomość</h5>
                       <div class="input-field col s12 pad30" >
                          <textarea v-model="editedItem.content" id="textarea1" class="materialize-textarea " readonly></textarea>
                          <button @click="cancel();" class="btn grey">Anuluj</button>
                          <button @click="destroyItem(editedItem);" class="btn red right">Usuń</button>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.action==='subsequence'">
                      
                      <div v-if="(this.newSubsequence && !this.confirmDestroy) || (this.editedSubsequenceIndex > -1 && !this.confirmDestroy)" v-on:keyup.enter="saveSubsequence(editedSubsequence);">
                         <h5 class="center-align">{{formTitleSequence}} sekwencję</h5>
                         <div class="input-field col s12 pad30" >
                            <input v-model="editedSubsequence.name" class="materialize border-box" autocomplete="off" />
                            <button @click="cancel();" class="btn grey">Anuluj</button>
                            <button @click="saveSubsequence(editedSubsequence);" class="btn right">Zapisz</button>
                          </div>
                      </div>
                      <div class="center align" v-else-if="!this.confirmDestroy">
                        <span class="grey-text text-darken-2">Dodaj sekwencję</span>
                        <div>
                         <a @click.stop.prevent="newItem();" class="btn-floating btn-large waves-effect waves-light center-plus-btn"
                         v-bind:class="{'pulse' : !this.activeSequence.items.length }">
                          <i class="material-icons">add</i></a>
                        </div>
                      </div>  


                    <div v-else>
                        <h5 class="center-align">Usuń sekwencję</h5>
                       <div class="input-field col s12 pad30" >
                          <input v-model="editedSubsequence.name" class="materialize border-box" autocomplete="off" />
                          <button @click="cancel();" class="btn grey">Anuluj</button>
                          <button @click="destroySubsequence(editedSubsequence);" class="btn red right">Usuń</button>
                        </div>
                      </div>
                    </div>
                </div>

              </div>

              
            </div>
          </div>
        </div>
      
      </transition>
    
   </div>
</template>


<script>
  import axios from 'axios';
  import { EventBus } from '../../eventbus';
  import parSeq from "../parent_sequence.vue";
   export default{
       name: 'LayModal',
       props: {
        openProp: Boolean,
        propActiveSequence: null,
       },
       data: function(){
          return {
            isOpen: false,
            activeSequence: this.propActiveSequence,
            newMessage: false,
            confirmDestroy: false,
            editSequence: false,
            deletedIndex: -1,
            editedIndex: -1,
            editedItem: {
              content: "",
              sequence_id: ""
            },
            deletedSubsequenceIndex: -1,
            editedSubsequenceIndex: -1,
            editedSubsequence: {
              sequence_id: "",
              name: ""
            },
            action: "message",
        };
       },
       computed: {
          formTitle() {
            return this.editedIndex === -1 ? "Dodaj" : "Edytuj";
          },
          formTitleSequence() {
            return this.editedSubsequenceIndex === -1 ? "Dodaj" : "Edytuj";
          },
          starting() {
            return this.activeSequence.starting
          }
        },
        mounted() {
          EventBus.$on('change-view-to-parent', (parent_id) => {
           console.log(parent_id);
           this.changeActiveSubsequenceToParent(parent_id);
          });
        },
       methods: {
        open: function()
        {
          console.log('open')
          this.isOpen = true;
          this.activeSequence = this.propActiveSequence; 
          this.initialize();
        },
        close: function()
        {
          console.log('close')
          this.isOpen = false; 
          this.editedIndex = -1;
          this.editedItem = {
              content: "",
            }
          this.$emit('close-modal', false);

        },
        initialize() {
        console.log('initialize');
        return axios
          .get(`sequences/${this.activeSequence.id}`)
          .then(response => {
             console.log(response.data);
             this.activeSequence = response.data.data;
             this.newMessage = false;
             this.confirmDestroy = false;
             this.deletedIndex = -1;
             this.editSequence = false;
             this.editedSubsequenceIndex = -1;
             this.deletedSubsequenceIndex = -1;
             this.action = "message";
           })
          .catch(e => {
             console.log(e);
          });
          
        },

        editItem(item) {
          console.log('edit');
          this.editedIndex = item.id;
          this.editedItem = Object.assign({}, item);
          this.newMessage = false;
          this.confirmDestroy = false;
          this.deletedIndex = -1;
          this.action = "message";
          
        },
        newItem() {
          console.log('new');
          this.editedIndex = -1;
          //this.editedItem = Object.assign({}, item);
          this.newMessage = true;
          this.action = "message";
          this.editedItem = {
              content: "",
            }
        },
        deleteItem(item) {
          this.confirmDestroy = true;
          this.editedItem = Object.assign({}, item);
          this.deletedIndex = item.id;
          this.editedIndex = -1;
          this.action = "message";
        },
        destroyItem(item) {
          const index = this.activeSequence.items.indexOf(item);
          //confirm("Are you sure you want to delete this item?");
          console.log('destroy');
          console.log(index) ;
          axios
            .delete(`messages/${item.id}`)
            .then(response => {
              console.log(response);
              console.log(response.data.json);
              //alert(response.data.json);
              this.initialize();
              this.updateSequences(); //for rerender sequences
            })
            .catch(error => {
              console.log(error);
            });
            console.log(this.activeSequence.items) ;
            this.activeSequence.items.splice(index, 1);
            console.log(this.activeSequence.items) ;
        },
        save(item) {
          if (this.editedIndex > -1) {
            axios
              .put(`messages/${item.id}`, {
                message: {
                  id: this.editedItem.id,
                  content: this.editedItem.content 
                },
              })
              .then(response => {
               console.log(response);
               this.initialize();
              })
              .catch(error => {
               console.log(error);
            });
          } else {
             axios
              .post(`sequences/${this.activeSequence.id}/messages/`, {
                 message:  {id: this.editedItem.id,
                  content: this.editedItem.content ,
                   sequence_id: this.activeSequence.id,
                 },
               })
               .then(response => {
                 console.log(response);
                 console.log("Created!");
                 this.initialize();
                 this.updateSequences(); //for rerender sequences
               })
               .catch(error => {
                 console.log(error);
             });

          }
          //this.close();
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, null);
          this.newMessage = false;
          this.action = "message";
        },
        cancel() {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, null);
          this.newMessage = false;
          this.confirmDestroy = false;
          this.deletedIndex = -1;
          this.action = "message";
          this.editedSubsequenceIndex = -1;
          this.editedSubsequence = Object.assign({}, null);
        },
       editSequenceName() {
          console.log('edit');
          this.editSequence = true;
                    
        },
       cancelSequenceName() {
          console.log('cancel');
          this.activeSequence.name = this.propActiveSequence.name;
          this.editSequence = false;
                    
        },
        saveSequenceName() {
          axios
              .put(`sequences/${this.activeSequence.id}`, {
                sequence: {
                  id: this.activeSequence.id,
                  name: this.activeSequence.name 
                },
              })
              .then(response => {
               console.log(response);
               //this.initialize();
               this.editSequence = false;
               this.updateSequences();
              })
              .catch(error => {
               console.log(error);
            });
        },
        updateSequences() {
          this.$emit('fetchSequencesTree', true);
          console.log('emit');
        },
        newSubsequence() {
          console.log('new');
          this.editedSubsequenceIndex = -1;
          this.action='subsequence';
          //this.editedItem = Object.assign({}, item);
          //this.newMessage = true;
        },
        editSubsequence(item) {
          console.log('edit');
          this.editedSubsequenceIndex = item.id;
          this.editedSubsequence = Object.assign({}, item);
          //this.newMessage = false;
          this.confirmDestroy = false;
          this.deletedIndex = -1;
          this.action = "subsequence";
        },
        deleteSubsequence(item) {
          this.confirmDestroy = true;
          this.editedSubsequence = Object.assign({}, item);
          this.deletedSubsequenceIndex = item.id;
          this.editedSubsequenceIndex = -1;
          this.action = "subsequence";
        },
        destroySubsequence(item) {
          const index = this.activeSequence.subsequences.indexOf(item);
          //confirm("Are you sure you want to delete this item?");
          console.log('destroy');
          console.log(index) ;
          axios
            .delete(`sequences/${item.id}`)
            .then(response => {
              console.log(response);
              console.log(response.data.json);
              //alert(response.data.json);
              this.initialize();
              this.updateSequences(); //for rerender sequences
            })
            .catch(error => {
              console.log(error);
            });
            console.log(this.activeSequence.items) ;
            this.activeSequence.subsequences.splice(index, 1);
            console.log(this.activeSequence.items) ;
        },
       saveSubsequence(item) {
          if (this.editedSubsequenceIndex > -1) {
            axios
              .put(`sequences/${item.id}`, {
                sequence: {
                  id: this.editedSubsequence.id,
                  name: this.editedSubsequence.name,
                },
              })
              .then(response => {
               console.log(response);
               this.initialize();
               this.updateSequences();
              })
              .catch(error => {
               console.log(error);
            });
          } else {
             axios
              .post('sequences/', {
                 sequence:  {
                  name: this.editedSubsequence.name ,
                  parent_id: this.activeSequence.id,
                 },
               })
               .then(response => {
                 console.log(response);
                 console.log("Created!");
                 this.initialize();
                 this.updateSequences();
               })
               .catch(error => {
                 console.log(error);
             });

          }
          //this.close();
          this.editedSubsequenceIndex = -1;
          this.editedSubsequence = Object.assign({}, null);
        },
        changeActiveSubsequenceToChild(subseq_id) {
          console.log(subseq_id);
          //console.log(this.activeSequence.subsequences);
          this.activeSequence.id = subseq_id; 
          this.initialize();
        },
        changeActiveSubsequenceToParent(parent_id) {
          console.log('hello from parent');
          //this.activeSequence.id = parent_id; 
          this.initialize();
        }
      },
       watch: {
          openProp () {
            if (this.openProp) this.open();
            else this.close();
          },
        },
      components: {
        parSeq,
      }
   }
</script>

<style>
  .modally {
  width: 100%;
  //height: 100%;
  margin: 0px auto;
  //padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  //transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
  //z-index:9999;
  display: block;
  //opacity:0.7;
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  //background: lightblue;
  width: 100;
  min-height: calc(100vh - 100px - 2em);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

}

.close {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  //background-color: gray;
  display: block;
  border: 2px solid #ccc;
  transition: all 300ms ease-in ;
  cursor: pointer;
  float: right;
}

.close .material-icons {
margin-top: 3px;
}

.close:hover {
  background-color: #ccc;  
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


  .overlay {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  //width: 100%;
  //height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 9999;
  //transition: opacity 0.2s ease;
}



.sequence-active {
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  top: -5px;
  background: #0d47a1;
  color: #fff;
  //border: 1px solid #ccc;
}

.modal-title {
  font-size: 2em;
}

.padding20 {
  padding: 20px;
}

.message-container:not(#assis_app .message-container) {
  width: 300px;
  margin: 0 auto;
  display:flex;
        flex-direction: column;
}

.message {
  border-radius: 10px;
  padding: 10px;
  color: black;
  //float:left;
  //clear:both;
  margin: 10px;
  margin-top: 0px;
  position:relative;
  transition: all .3s ease;
  text-align:left;
  min-width:120px;
  display: inline-block;
  max-width: 100%;
  display: inline-block;
  margin: 5px auto;
  overflow-wrap: break-word;
}

.message-block {
  float:left;
  clear:both;
  max-width: 100%;
  word-break: keep-all;
}

.message:hover {
  //color: #424242;
}

.message-overlay {
  position:absolute;
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(50, 50, 50, 0.3);
  width:100%;
  height:100%;
  border-radius: 10px;
  display:none;
  justify-content: center;
  align-items: center;
}

.message:hover .message-overlay {
  display:flex;
  transition: all .3s ease;
}

.message-btn {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  display: block;
  border: none;
  transition: all 300ms ease-in ;
  cursor: pointer;
  float: right;
  background-color: #757575; 
  transition: all .3s ease;
  color: #c5cae9; 
}

.message-btn .material-icons {
margin-top: 3px;
color: #c5cae9;
}

.message-btn:hover {
  background-color: #0277bd; 

}

.message-btn.delete:hover  {
  background-color: #F44336; 

}

.message-btn:hover .material-icons {
  color: white;
}

.margin0 {
  margin:0;
}

.message.active {
  background-color: #0277bd !important;
  color: white;
}

.message.to_delete {
  background-color: rgb(244, 67, 54) !important;
  color: white;
  margin-bottom: 0px !important;
}

.scrollable {
  overflow-y: auto;
  height: calc(100% - 4em - 87px);
  max-height: calc(100vh - 4em - 200px);
  margin-left:10px;
}

.scrollable:hover {
  //overflow-y: auto;
  margin-left:0px;
}
.center-vh {
  position: relative;
  height: calc(100vh - 2em - 190px);
}
.center-vh > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  right: 0;
  left: 0;
  max-height: calc(100vh - 4em - 200px);
}

.height-modal-item {
    height: calc(100vh - 2em - 190px);
}

.center-plus-btn {
  margin: 17px 0px;
}

.inline-btn {
  display: inline-block !important;
  float:none;
}

.input-sequence-name {
    padding: 0.5em 0.75em  !important;
    text-decoration: none !important;
    display: inline-block !important;
    border-radius: 5px !important;
    position: relative !important;
    top: -5px !important;
    background: #0d47a1 !important;
    color: #fff !important;
    //border: 1px solid #e9453f !important;
    height: 22px !important;
    width: 200px !important;
    top: 0 !important;
}

.input-sequence-name:focus {
  box-shadow: none !important;
}

.input-field-margin0 {
  margin-bottom: 0;
  margin-top: 0;
}

.disabled {
  cursor: not-allowed;
  color: #bdbdbd;
  background-color: #eeeeee ;
  border-color: #eeeeee;
}

.disabled:hover {
  background-color: #eeeeee ;
  border-color: #eeeeee;
}
.sequence-container {
  //width: 200px;
  margin: 0 auto;
  display:flex;
        flex-direction: column;
        justify-content: center;
}

.sequence- {
  border-radius: 10px;
  padding: 10px;
  color: white;
  float:left;
  clear:both;
  margin: 10px;
  margin-top: 0px;
  position:relative;
  transition: all .3s ease;
  text-align:left;
  min-width:100px;
  background-color: #0d47a1;
  transition: ease all 300ms;
}

.sequence::first {
  margin-top: 10px;
}

.sequence:hover {
  background-color: #04152f;
}

.sequence-overlay {
  position:absolute;
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(20, 20, 20, 0.8);
  width:100%;
  height:100%;
  border-radius: 10px;
  display:none;
  justify-content: center;
  align-items: center;
}

.sequence:hover .sequence-overlay {
  display:flex;
  transition: all .3s ease;
}

.sequence-btn {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  display: block;
  border: none;
  transition: all 300ms ease-in ;
  cursor: pointer;
  float: right;
  background-color: #757575; 
  transition: all .3s ease;
  color: #c5cae9; 
}

.sequence-btn .material-icons {
margin-top: 3px;
color: #c5cae9;
}

.sequence-btn:hover {
  background-color: #0277bd; 

}

.sequence-btn.delete:hover  {
  background-color: #F44336; 

}

.sequence-btn:hover .material-icons {
  color: white;
}

.vertical_line_cont {
  //position: relative;
 
}
.vertical_line {
  border-left: 2px solid #ccc;
  height: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width:0;
}

.vertical_line_short {
  border-left: 2px solid #ccc;
  height: 8px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width:0;
}

.container_border {
  margin:0;
  border-radius: 4px;
  border: 2px solid #ccc;
  padding: 10px;
  position:relative;
}

.parent_sequence {
  align-self: flex-start;
  //border: 2px solid #ccc;
  background-color: #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-flex;
  border-radius: 5px;
  color: #333;
  //position: relative;
  //top: 1px;
  cursor: default;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

input.border-box {
  box-sizing: border-box !important;
}

.pad30 {
  padding:30px;
}


.panel-inline {
  display: inline-block;
}

.card-panel {
  padding: 6px 12px;
  margin:0;
  border-radius:12px;
  box-shadow: none;
}
.card-panel i {
  margin-right:5px;
}

.card-panel-up {
  position: relative;
  top: -8px;
}

.sequence-active-panel {
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  background: #0d47a1;;
  color: #fff;
  //border: 1px solid #e9453f;
  margin: 0 auto;
  position: static !important;
}

.pad-8 {
padding: 8px;
}

.static {
  position: static !important;
  transform: none !important;
}

.sequence_hov {
  transition: all 300ms ease;
  cursor: pointer;
}

.sequence_hov:hover {
      background: #0d47a1;
    color: #fff;

}

.button_area_inlne {
  clear:  both;
}
</style>
<template>
    <div id="login" class="z-depth-2" v-on:keyup.enter.prevent="submit()">
        <div class="panellog">
          <div class="light-blue  darken-4 white-text headlog center-align"><h4>Reset hasła</h4></div>
          <div v-show="loading">
            <div class="progress margin0">
              <div class="indeterminate"></div>
            </div>
          </div>
          <div class="center-align mar">
            <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Wpisz swój adres email powiązany z&nbsp;istniejącym kontem w&nbsp;systemie.</div>
          </div>
          <div v-show="!success">
              <div class="input-field mar">   
                <input v-model="email" type="email" id="email" />
                <label for="email" class="">Email</label>
              </div>
          </div>
          <div class="center-align">
            <transition name='fade'>
              <span class="blue-text" v-if="success">{{ success }}</span>
            </transition>
            <transition name='fade'>
              <span class="red-text" v-if="error">{{ errorsDesc[error] }}</span>
            </transition>
          </div>
          <p v-show="!success" class="center-align mar-">
                  <button type="button" class="btn" v-on:click.prevent="submit()">Wyślij instrukcję</button>
          </p>


          <div class="center-align mar">
            <div class="grey-text text-darken-2"> Masz już konto?</div>
            <router-link to="/">Zaloguj się</router-link><br><br>

            <div class="grey-text text-darken-2"> Nie masz jeszcze konta?</div>
            <router-link to="/zaloz-konto">Zarejestruj się</router-link>
          </div>

      </div>
    </div>

</template>

<script>
import { plainAxiosInstance } from '../backend/axios/index.js'
export default {
  name: 'ForgotPassword',
  data () {
    return {
      loading: false,
      email: '',
      success: '',
      error: '',
      errorsDesc: {
        'Not found': 'Adres email nie istnieje w systemie',
        'Unprocessable Entity': 'Nieznany błąd'
      },
    }
  },
  created () {
    //this.checkSignedIn()
  },
  updated () {
    //this.checkSignedIn()
  },
   methods: {
    submit () {
      this.loading = true;
      plainAxiosInstance.post('/reset-hasla', { email: this.email })
        .then(() => this.submitSuccessful())
        .catch(error => this.submitFailed(error))
    },
    submitSuccessful () {
      this.success = 'Email z instrukcją zmiany hasła został wysłany'
      this.error = ''
      this.email = ''
      this.loading = false;
    },
    submitFailed (error) {
      this.error = (error.response && error.response.data && error.response.data.error) || ''
      this.loading = false;
    }
  }
}
</script>

<style scoped>
    #login {
        width: 500px;
        //border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
         position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
    }
    .panellog {
      margin:0;
      overflow-y: auto;
    max-height: 90vh;
    }
    .mar {
      margin: 40px;
    }

    .mar input {
      width:100%;
      box-sizing: border-box;
    }
    .headlog {
    
    }

    h4 {
      margin: 0;
      padding:10px;
    }
</style>
<template>
  

  <li>
    <a @click="emitGlobalSequenceShow">{{ node.name }}</a>
                
                <div class="c_container">
                  <div class="red- c_btn">
                    <span @click="toggleShowItems()">
                      <span v-show="!this.showItems"><i  class="material-icons ">expand_more</i></span>
                      <span v-show="this.showItems"><i v-show="this.showItems" class="material-icons ">expand_less</i></span>
                                
                     </span>
                  </div>
                  <div v-show="this.showItems" class="message-container grey- lighten-2- center-align">
                    <div v-if="node.items" class="container_border" >
                      <div >
                        <div class="center-align grey-text text-darken-2">Wiadomości ({{node.items.length}}) 
                      </div>
                        <div v-for="item in node.items"
                             :key="item.id" 
                             class='message message-block grey lighten-4'
                             v-html="item.content">

                        </div>
                      </div>
                    </div>
                    <div v-else class="center-vh-">
                        <div class="container_border">
                          <span class="grey-text text-darken-2"> Brak wiadomości</span><br>
                          <i class="large material-icons grey-text text-lighten-2">chat</i>
                        </div>
                    </div>

                  </div>
                </div>
    <ul v-if="node.children && node.children.length">
      <node v-for="child in node.children" :node="child" :key="child.id"></node>
    </ul>
  </li>
</template>

<script>
import LayModal from "./layouts/LayModal.vue"
import { EventBus } from '../eventbus';

export default {
  name: "node",
  data: () => ({
    showItems: false,
  }),
  props: {
    node: Array/Object
  },
  mounted() {
    this.expandMore();
  },
  methods: {
    emitGlobalSequenceShow() {
      //$event.$emit('sequence-show', node.id)
      EventBus.$emit('sequence-show', this.node.id);
    },
    toggleShowItems() {
      this.showItems = !this.showItems;
    },
    expandMore() {
      EventBus.$on('show-items', (value) => {
           console.log('expandMore');
           if ((value === true && this.showItems === false) || (value === false && this.showItems === true)) {
            this.toggleShowItems();
           }
           //let seqenece_id = item_id;
           //this.openModalShow(seqenece_id);
        });
    }
  }
  
};
 //@click="$parent.openModalShow(node.id)" 
</script>


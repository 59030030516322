<template>
  <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->

     <h1>Twoje Konto</h1>
      <div class="diagram white pad15">
        
      
      
      <div v-on:keyup.enter.prevent="update()">
        <div class="panellog">

            <div v-if="company.status == 'created'" class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Dziękujemy za założenie konta. Uzupełnij dane do faktury oraz dane kontaktowe zanim przejdziesz dalej.</div>

            <h5>Dane do faktury</h5>
          
            <div class="input-field mar">
                <input id="company_name" autocomplete="off" type="text" v-model="company.company_name" v-on:keyup="change()" />
                <label for="company_name" v-bind:class="company.company_name !== '' ? 'active' : '' ">Nazwa firmy</label>
                 <div class="mar- mar-inline-">
                  <span v-if="validation.company.company_name == true " class="green-text">
                  <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                  </span>
                  <span v-else-if="(validation.company.company_name == false)" class="red-text">
                    <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                  </span>
                </div>
            </div>

            <div class="input-field mar">
                <input id="company_nip" autocomplete="off" type="text" v-model="company.nip" v-on:keyup="change()" />
                <label for="company_nip" v-bind:class="company.nip !== '' ? 'active' : '' ">NIP</label>
                 <div class="mar- mar-inline-">
                  <span v-if="validation.company.nip == true " class="green-text">
                  <i class="material-icons left tiny green-text">check_circle</i>NIP prawidłowy
                  </span>
                  <span v-else-if="(validation.company.nip == false)" class="red-text">
                    <i class="material-icons left tiny red-text">cancel</i>NIP nieprawidłowy
                  </span>
                </div>
            </div>
           

            <div class="row">
              <div class="col s6">

                  <div class="input-field mar">
                      <input id="company_street" autocomplete="off" type="text" v-model="company.street" v-on:keyup="change()" />
                      <label for="company_street" v-bind:class="company.street !== '' ? 'active' : '' ">Ulica</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.company.street == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.company.street == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                  </div>

              </div>
              <div class="col s3">

                <div class="input-field mar">
                      <input id="company_street_nr" autocomplete="off" type="text" v-model="company.street_nr" v-on:keyup="change()" />
                      <label for="company_street_nr" v-bind:class="company.street_nr !== '' ? 'active' : '' ">Nr budynku</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.company.street_nr == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.company.street_nr == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                  </div>

              </div>
              <div class="col s3">

                <div class="input-field mar">
                      <input id="company_flat_nr" autocomplete="off" type="text" v-model="company.flat_nr" v-on:keyup="change()" />
                      <label for="company_flat_nr" v-bind:class="company.flat_nr !== '' ? 'active' : '' ">Nr lokalu</label>
                  </div>

              </div>

               
              <div class="col s6">

                  <div class="input-field mar">
                      <input id="company_zip" autocomplete="off" type="text" v-model="company.zip" v-on:keyup="change()" />
                      <label for="company_zip" v-bind:class="company.zip !== '' ? 'active' : '' ">Kod pocztowy</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.company.zip == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Kod pocztowy prawidłowy
                        </span>
                        <span v-else-if="(validation.company.zip == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Kod pocztowy błędny
                        </span>
                      </div>
                  </div>

              </div>

                <div class="col s6">

                  <div class="input-field mar">
                      <input id="company_city" autocomplete="off" type="text" v-model="company.city" v-on:keyup="change()" />
                      <label for="company_city" v-bind:class="company.city !== '' ? 'active' : '' ">Miasto</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.company.city == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.company.city == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                  </div>

              </div>
              


            </div>

            <h5>Dane kontaktowe</h5>

            <div class="row">
              <div class="col s6">

                  <div class="input-field mar">
                      <input id="user_first_name" autocomplete="off" type="text" v-model="user.first_name" v-on:keyup="change()" />
                      <label for="user_first_name" v-bind:class="user.first_name !== '' ? 'active' : '' ">Imię</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.user.first_name == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.user.first_name == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                  </div>

              </div>
              <div class="col s6">

                  <div class="input-field mar">
                      <input id="user_last_name" autocomplete="off" type="text" v-model="user.last_name" v-on:keyup="change()" />
                      <label for="user_last_name" v-bind:class="user.last_name !== '' ? 'active' : '' ">Nazwisko</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.user.last_name == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.user.last_name == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                      </div>
                  </div>

              </div>

              <div class="col s6">

                  <div class="input-field mar">
                      <input id="user_email" autocomplete="off" type="text" v-model="user.email" v-on:keyup="change()" />
                      <label for="user_email" v-bind:class="user.email !== '' ? 'active' : '' ">Email/Login</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.user.email == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Prawidłowy email
                        </span>
                        <span v-else-if="(validation.user.email == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Błędny email
                        </span>
                      </div>
                  </div>

              </div>

              <div class="col s2">

                  <div class="input-field mar">
                      <input disabled autocomplete="off" type="text" value="+48" />
                  </div>

              </div>

              <div class="col s4">

                  <div class="input-field mar">
                      <input id="user_tel" autocomplete="off" type="text" v-model="user.tel" v-on:keyup="change()" />
                      <label for="user_tel" v-bind:class="user.tel !== '' ? 'active' : '' ">Telefon</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.user.tel == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Prawidłowy telefon
                        </span>
                        <span v-else-if="(validation.user.tel == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Błędny telefon
                        </span>
                      </div>
                  </div>

              </div>



            </div>
            


            
            

            <div class="mar center-align">
              <transition name='fade'>
                <span class="red-text" v-if="error">Użytkownik o podanym adresie email już istnieje.</span>
              </transition>
            </div>
            <p class="center-align mar-">
                    <button type="button" v-bind:class="this.submitOn === true ? '' : 'disabled'" class="btn" v-on:click.prevent="update()">Zapisz</button>
            </p>

           </div>
         
          
      </div>
    </div>
    </div>

       <!-- request finished -->

    </div>
  </div>
</template>


<script>
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js';
import { Navigation } from '../navigation';
import { EventBus } from '../eventbus';
import LaySidebar from './layouts/LaySidebar.vue';
 export default {
   name: 'YourAccount',
   components: {
    LaySidebar,
   },
   data: () => ({
     loading: false,
     success: false,
     oldStatus: '',
     company: { 
              company_name: '',
              nip: '',
              street: '',
              street_nr: '',
              flat_nr: '',
              city: '',
              zip: '',
              },
      user: {
            first_name: '',
            last_name: '',
            email: '',
            tel: '',
      },
     validation: {
                  company: {
                            company_name: false,
                            nip: false,
                            street: false,
                            street_nr: false,
                            city: false,
                            zip: false,
                  },
                  user: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    tel: false,
                  }
     },
     submitOn: false,
     error: '',

     //user: null
   }),


  created: function () {
    if (!localStorage.signedIn) {
      this.$router.replace('/')
    } else {
      //this.fetchAccount();
      //this.$emit('accountStatus', this.account.status);
    }
   
  },

  mounted: function() {
    //this.$emit('accountStatus', this.account.status);
    this.fetch();
     this.$nextTick(M.updateTextFields);
  },
   

   methods: {
     validCompanyName () {
       if (this.company.company_name !== null && this.company.company_name.length > 0) {
        this.validation.company.company_name = true;
       } else {
        this.validation.company.company_name = false;
       }
     },
     validStreet () {
       if (this.company.street !== null && this.company.street.length > 0) {
        this.validation.company.street = true;
       } else {
        this.validation.company.street = false;
       }
     },
     validStreetNr () {
       if (this.company.street_nr !== null && this.company.street_nr.length > 0) {
        this.validation.company.street_nr = true;
       } else {
        this.validation.company.street_nr = false;
       }
     },
     validCity () {
       if (this.company.city !== null && this.company.city.length > 0) {
        this.validation.company.city = true;
       } else {
        this.validation.company.city = false;
       }
     },
     validFirstName () {
       if (this.user.first_name !== null && this.user.first_name.length > 0) {
        this.validation.user.first_name = true;
       } else {
        this.validation.user.first_name = false;
       }
     },
     validLastName () {
       if (this.user.last_name !== null && this.user.last_name.length > 0) {
        this.validation.user.last_name = true;
       } else {
        this.validation.user.last_name = false;
       }
     },
     validNIP () {
          let nip = this.company.nip;
          if(typeof nip !== 'string') {
              this.validation.company.nip = false;
              console.log('not string');
          } else {    

            nip = nip.replace(/[\ \-]/gi, '');

            let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(nip.substring(9, 10));
            let weightCount = weight.length;
            for (let i = 0; i < weightCount; i++) {
                sum += (parseInt(nip.substr(i, 1)) * weight[i]);
            }
            
            if (sum % 11 === controlNumber && nip.length === 10) {
              this.validation.company.nip = true;
              console.log('nip ok');
            } else {
              this.validation.company.nip = false;
              console.log('not nip');
            }
          }
      
     },
     validZip () {
       var format = /\d{2}-\d{3}/;

       if (this.company.zip !== null && format.test(this.company.zip) && this.company.zip.length == 6) {
        this.validation.company.zip = true;
       } else {
        this.validation.company.zip = false;
       }
     },
     validEmail() {
     var format = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      if(this.user.email !== null && format.test(this.user.email)){
        this.validation.user.email = true;
      } else {
        this.validation.user.email = false;
      }
    },
    validTel() {
      var format = /\d{9}/;

       if (this.user.tel !== null && format.test(this.user.tel)) {
        this.validation.user.tel = true;
       } else {
        this.validation.user.tel = false;
       }
    },
    checkValidation() {
      if (this.validation.company.company_name === true
        && this.validation.company.nip === true
        && this.validation.company.street === true
        && this.validation.company.street_nr === true
        && this.validation.company.city === true
        && this.validation.company.zip === true
        && this.validation.user.first_name === true
        && this.validation.user.last_name === true
        && this.validation.user.email === true
        && this.validation.user.tel === true
        ) {
        this.submitOn = true;
      } else {
        this.submitOn = false; 
      }
      console.log(this.submitOn);
    },
     change() {
      this.validCompanyName();
      this.validNIP();
      this.validStreet();
      this.validStreetNr();
      this.validCity();
      this.validZip();
      this.validFirstName();
      this.validLastName();
      this.validEmail();
      this.validTel();
      this.checkValidation();
     },
     fetch() {
      this.loading = true;
      securedAxiosInstance.get('/account')
       .then(response => {
         console.log(response.data.data);
         this.company = response.data.data.account;
         this.user = response.data.data.user;
         this.loading = false;
         this.change();
         if ( this.oldStatus == 'created' && this.company.status == 'completed') {
                //redirect to settings for update
                console.log('emit clicked')
                Navigation.$emit('updateStatusAccount', 'completed');
              }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },
     update() {
      if ( this.submitOn === true) {
        this.loading = true;
        this.oldStatus = this.company.status;
        securedAxiosInstance
                .put('account/', {
                  account: this.company,
                  user: this.user,
                })
                .then(response => {
                 console.log(response);
                 this.loading = false;
                 EventBus.$emit('account-reload', 'reload');
                 this.fetch();

                 

                 //this.initialize();
                 //this.updateSequences();
                })
                .catch(error => {
                 console.log(error);
                 this.loading = false;
                 this.fetch();
              });
        }
     },
     
   },
 };
 //alert('account');

 </script>

 <style scoped>
input[type=text]:not(.browser-default) {
  box-sizing: border-box !important;
}

.input-field .mar  {
  margin-bottom: 12px !important;
}

.panellog {
max-width: 800px;
margin: 0 auto;
  }
 </style>}
}

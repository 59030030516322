<template>
  <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->

     <h1>Ustawienia</h1>
      <div class="diagram white pad15">
        
      
      
      <div v-on:keyup.enter.prevent="update()">
        <div class="panellog">

            <div v-if="company.status == 'completed'" class="card-panel panel-inline amber lighten-4 grey-text text-darken-2 mar-bot-10"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Dokończ najważniejsze ustawienia i rozpocznij pracę z AssisBot&trade;</div>
            
            

           
              <h5>Chatbot na stronie www</h5>
              <h6>Widoczność na stronie</h6>
              <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Możesz tymczasowo ukryć chatbota na swojej stronie www, np. gdy zmieniasz jego strukturę, aby użytkownicy odwiedzający Twoją stronę zobaczyli dopiero efekt końcowy.</div>
            <!-- Switch -->
            <div class="input-field mar">
              <div class="switch">
                <label>
                  Ukryty
                  <input type="checkbox" v-bind:disabled="!submitIntegrity" v-model="company.chatbot_on" v-on:click="switchVisible()">
                  <span class="lever"></span>
                  Widoczny
                </label>
              </div>
              <transition name="fade">
                      <span v-if="changeVisibility" class="green-text left">
                        <i class="material-icons left tiny green-text">check_circle</i>Zaktualizowano
                      </span>
              </transition>
            </div>
            <h6>Wyrównanie</h6>
            
            <div class="row">
              <div class="col s4">
                   
                  <label>
                    <input name="chatbot_position_align"  type="radio" v-model="widgetPositionHelper.align" value="left" v-on:change="calcWidgetPosition();" :checked="this.checked == 'left'" />
                    <span id="left">lewy dolny róg</span>
                  </label>
                  <label>
                    <input name="chatbot_position_align"  type="radio" v-model="widgetPositionHelper.align" value="right" v-on:change="calcWidgetPosition();" :checked="widgetPositionHelper.align == 'right'" />
                    <span id="right">prawy dolny róg</span>
                  </label>
                  
                
                </div>
                <div class="col s4">
                  <div class="input-field mar">
                      <input id="chatbot_position_x" autocomplete="off" type="text" v-model="widgetPositionHelper.length_x" v-on:keyup="change()" v-on:change="calcWidgetPosition()" />
                      <label for="chatbot_position_x">Odległość od krawędzi x</label>
                      <div class="mar- mar-inline-">
                        <span v-if="validation.company.widget_pos_x == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.company.widget_pos_x == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                    </div>
                    </div>

                </div>
                <div class="col s4">
                  <div class="input-field mar">
                      <input id="chatbot_position_y" autocomplete="off" type="text" v-model="widgetPositionHelper.length_y" v-on:keyup="change()" v-on:change="calcWidgetPosition()" />
                      <label for="chatbot_position_y">Odległość od krawędzi y</label>
                      <div class="mar- mar-inline-">
                        <span v-if="validation.company.widget_pos_y == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                        </span>
                        <span v-else-if="(validation.company.widget_pos_y == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                        </span>
                    </div>
                    </div>

                </div>
              </div>
              <div class="col s12">
                

                <h6>Chat użytkownika</h6>
                <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Możesz wyłączyć możliwość wysyłania wiadomości przez użytkownika do chatbota.</div>
                <div class="input-field mar">
                  <div class="switch">
                    <label>
                      Wyłączony
                      <input type="checkbox" v-model="company.chatbot_input_on">
                      <span class="lever"></span>
                      Aktywny
                    </label>
                  </div>
                </div>
              
                <p class="center-align mar-">
                    <button type="button" v-bind:class="this.submitOn === true ? '' : 'disabled'" class="btn" v-on:click.prevent="update()">Zapisz</button>
            </p>


            </div>

            <h5>Personalizacja</h5>
             <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Logo będzie widoczne dla wszystkich użytkowników chatbota. Najlepszy efekt uzyskasz, gdy logo będzie miało wymiary <b>148px</b> x <b>148px</b>. Obługiwane formaty plików to <b>.png, .jpg i .jpeg</b></div>

            <div class="row">
              <div class="col s12">
                <form @submit.prevent="uploadLogo()">

                    <div v-if="this.company.logo_url" class="center-align pad10">
                      <img :src="this.company.logo_url" style="max-width:148px;" />
                    </div>

                    <div v-else class="grey-text text-darken-2 center-align">
                        <i class="large material-icons">account_circle</i><br>
                        <span>brak pliku</span>
                    </div>

                    <div class="file-field input-field">
                      <div class="btn">
                        <span>Wybierz plik</span>
                        <input type="file" @change='selectedLogo' accept='image/*' />
                      </div>
                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text">
                      </div>
                    </div>
                    <div class="center-align pad10">
                      <button @click.prevent="uploadLogo()" type="button" class="btn center-align" v-bind:class="this.submitOn === true ? '' : 'disabled'" >Zapisz logo</button>
                    </div>

                </form>

                <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2 mar"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Nazwa będzie widoczna dla wszystkich użytkowników w nagłówku chatbota.</div>
                <div class="input-field mar">
                    <input id="chatbot_name" autocomplete="off" type="text" v-model="company.chatbot_name" v-on:keyup="change()" />
                    <label for="chatbot_name" v-bind:class="company.chatbot_name !== '' ? 'active' : ''" :checked="widgetPositionHelper.align == 'left'" style="">Nazwa chatbota</label>
                     <div class="mar- mar-inline-">
                      <span v-if="validation.company.chatbot_name == true " class="green-text">
                      <i class="material-icons left tiny green-text">check_circle</i>Pole wypełnione prawidłowo
                      </span>
                      <span v-else-if="(validation.company.chatbot_name == false)" class="red-text">
                        <i class="material-icons left tiny red-text">cancel</i>Pole wypełnione błędnie
                      </span>
                    </div>
                </div>

           
              </div>
              <div class="col s2">

                  <div class="input-field mar">
                      <input disabled autocomplete="off" type="text" value="http(s)://" />
                  </div>

              </div>

              <div class="col s10">

                  <div class="input-field mar">
                      <input id="company_chatbot_url" autocomplete="off" type="text" v-model="company.chatbot_url" v-on:keyup="change()" />
                      <label for="company_chatbot_url" v-bind:class="company.chatbot_url !== '' ? 'active' : '' ">Adres strony www</label>
                       <div class="mar- mar-inline-">
                        <span v-if="validation.company.chatbot_url == true " class="green-text">
                        <i class="material-icons left tiny green-text">check_circle</i>Prawidłowy adres strony www
                        </span>
                        <span v-else-if="(validation.company.chatbot_url == false)" class="red-text">
                          <i class="material-icons left tiny red-text">cancel</i>Błędny adres strony wwww
                        </span>
                      
                  </div>

              </div>
            </div>

              </div>
              <h6>Schemat kolorysyczny</h6>
              <div class="row">
                <div class="col s3">
                  <div class="select_color_scheme" v-bind:class="{'selected': this.company.chatbot_color_scheme === null}" @click="setColorScheme(null);"></div>
                </div>
                <div class="col s3">
                  <div class="select_color_scheme red_scheme" v-bind:class="{'selected': this.company.chatbot_color_scheme === 'red_scheme'}" @click="setColorScheme('red_scheme');"></div>
                </div>
                <div class="col s3">
                  <div class="select_color_scheme green_scheme" v-bind:class="{'selected': this.company.chatbot_color_scheme === 'green_scheme'}" @click="setColorScheme('green_scheme');"></div>
                </div>
                <div class="col s3">
                  <div class="select_color_scheme beige_scheme" v-bind:class="{'selected': this.company.chatbot_color_scheme === 'beige_scheme'}" @click="setColorScheme('beige_scheme');"></div>
                </div>
              </div>

            <p class="center-align mar-">
                    <button type="button" v-bind:class="this.submitOn === true ? '' : 'disabled'" class="btn" v-on:click.prevent="update()">Zapisz</button>
            </p>

            <div class="col s12" id="code_section">
                <h5>Kod integracyjny</h5>

                <div><br><br></div>
                 <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Skopiuj poniższy kod i wklej go w kodzie Twojej strony www, przed znacznikiem zamykającym <b>&lt;/body&gt;</b>.</div>

                <div class="input-field mar">
                    <textarea id="code" class="grey lighten-2 grey-text text-darken-2" autocomplete="off" type="text" v-model="integrationCode" readonly />
                     
                    <button type="button" class="btn left" v-on:click="copyCode()">Kopiuj</button>
                     
                      <transition name="fade">
                      <span v-if="copied" class="green-text left">
                        <i class="material-icons left tiny green-text">check_circle</i>Skopiowano
                      </span>
                      </transition>
                      
<br>
                </div>
                
                

           
              </div>






            
            

            <div class="mar center-align">
              <transition name='fade'>
                <span class="red-text" v-if="error">Użytkownik o podanym adresie email już istnieje.</span>
              </transition>
            </div>
            <div class="center-align">
              <transition name="fade">
              <div v-if="integrated" class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper center-align">
                  <div><i class="material-icons  medium green-text text-darken-3">check_circle</i><br>Twoja strona jest poprawnie zintegrowana z AssisBot&trade;</div>
              </div>
              <div v-else class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper center-align">
                  <div><i class="material-icons  medium red-text text-darken-3">error</i><br>Upewnij się, że kod integracyjny został poprawnie umieszczony na twojej stronie www.</div>
              </div>
              </transition>
              <transition>
                    <div v-show="loadingIntegrity">
                          <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                    <div class="progress">
                      <div class="indeterminate"></div>
                    </div>
                  </div>
              </transition>
            </div>
            <p class="center-align mar-">
                    <button type="button" v-bind:class="this.submitIntegrity === true ? '' : 'disabled'" class="btn" v-on:click.prevent="checkIntegrity()">Sprawdź poprawność integracji</button>
            </p>

           

           <h5>Backup</h5>
            <div class="card-panel panel-inline amber lighten-4 grey-text text-darken-2 mar"><i class="material-icons left orange-text text-lighten-3">info_outline</i>W tym miejscu możesz exportować dane z panelu Assibot&trade;, aby przechować je jako backup. Możesz także zaimportować dane z wczesniej wykonanego backupu. Backup obejmuje:<br><ul>
              <li>- ustawienia chatbota na stronie www<i>(bez logo)</i></li>
              <li>- sekwencje i wiadomości<i>(bez plików)</i></li>
              <li>- bazy danych</li>
              <li>- ustawienia konta użytkownika<i>(bez hasła)</i> i panelu Assibot&trade;<i>(bez logo)</i></li>
            </ul></div>
         
            <br><br>

            <transition>
                    <div v-show="loadingBackup">
                          <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                    <div class="progress">
                      <div class="indeterminate"></div>
                    </div>
                  </div>
              </transition>

            <div class="row">
              <div class="col s12">
                <p  class="center-align mar">
                        <transition name="fade">
                          <span v-if="this.backupSuccess" class="green-text ">
                          <i class="material-icons tiny green-text">check_circle</i>{{this.backupSuccess}}
                          </span>
                        </transition>
                         <transition name="fade">
                          <span v-if="this.backupError" class="red-text">
                          <i class="material-icons tiny red-text">error</i>{{this.backupError}}
                          </span>
                        </transition>
                    </p>
              </div>

              <div class="col s6">
                 <button type="button" v-bind:class="this.backupAction === null ? '' : 'disabled'" class="btn" v-on:click.prevent="exportBackup()">Exportuj dane</button>
              </div>

              <div class="col s6">
                  <form @submit.prevent="importBackup()">

                    <div class="file-field input-field">
                      <div class="btn" v-bind:class="this.backupAction === null ? '' : 'disabled'">
                        <span>Wybierz plik</span>
                        <input type="file" @change='selectedBackup' accept='text/plain' />
                      </div>
                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text">
                      </div>
                    </div>
                    <div class="center-align pad10">
                      <button @click.prevent="importBackup()" type="button" class="btn center-align" v-bind:class="this.backupAction === null ? '' : 'disabled'" >Importuj dane</button>
                    </div>

                </form>
              </div>

              
            </div>

            </div>

          </div>
      </div>
    </div>
    </div>

       <!-- request finished -->

    </div>
  </div>
</template>


<script>
import { EventBus } from '../eventbus';
import axios from 'axios';
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js';
import { Navigation } from '../navigation';
 export default {
   name: 'settings',
   data: () => ({
     loading: false,
     success: false,
     company: { 
              chatbot_name: '',
              chatbot_url: '',
              chatbot_input_on: '',
              chatbot_widget_position: '',
              chatbot_color_scheme: null,
              logo: null,
              //chatbot_on: false,
              },
     validation: {
                  company: {
                            chatbot_name: false,
                            chatbot_url: false,
                            widget_pos_x: false,
                            widget_pos_y: false,
                  },
     },
     submitOn: false,
     submitIntegrity: false,
     error: '',
     integrationCode: null,
     integrated: false,
     loadingIntegrity: false,
     copied: false,
     integrationCodeTemplate: '<script>var assisAppId = "API_KEY";<\/script><script src="https://api.assisbot.pl/api.js"><\/script>',
     oldStatus: '',
     changeVisibility: false,
     widgetPositionHelper: {
      align: null,
      length_x: 20,
      length_y: 16,
      },
      checked: '',
      uploadFile: null,
      backupAction: null,
      loadingBackup: false,
      backupDownloadURL: null,
      backupFile: null,
      backupSuccess: null,
      backupError: null,


     //user: null 
   }),


  created: function () {
    if (!localStorage.signedIn) {
      this.$router.replace('/')
    } else {
      //this.fetchAccount();
      //this.$emit('accountStatus', this.account.status);
    }
   
  },

  mounted: function() {
    //this.$emit('accountStatus', this.account.status);
    this.fetch();
    this.checkIntegrity();
    this.$nextTick(M.updateTextFields);
  },
  

  computed: {
    
  },

   methods: {
     validChatbotName () {
       if (this.company.chatbot_name !== null && this.company.chatbot_name.length > 0) {
        this.validation.company.chatbot_name = true;
       } else {
        this.validation.company.chatbot_name = false;
       }
     },
     validChatbotURL () {
       var format = new RegExp(
       //'^(https?:\\/\\/)?'+ // protocol
        '^((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

       if (this.company.chatbot_url !== null && format.test(this.company.chatbot_url)) {
        this.validation.company.chatbot_url = true;
       } else {
        this.validation.company.chatbot_url = false;
       }
     },
     validLengthX () {
       var format = new RegExp(/^\d+$/);
       if (format.test(this.widgetPositionHelper.length_x)) {
        this.validation.company.widget_pos_x = true;
       } else {
        this.validation.company.widget_pos_x = false;
       }
     },

     validLengthY () {
       var format = new RegExp(/^\d+$/);
       if (format.test(this.widgetPositionHelper.length_x)) {
        this.validation.company.widget_pos_y = true;
       } else {
        this.validation.company.widget_pos_y = false;
       }
     },
     
    checkValidation() {
      if (this.validation.company.chatbot_name === true
        && this.validation.company.chatbot_url === true
        && this.validation.company.widget_pos_x === true
        && this.validation.company.widget_pos_y === true
        ) {
        this.submitOn = true;
      } else {
        this.submitOn = false; 
      }
      console.log(this.submitOn);
    },
    checkValidationIntegrityCode() {
      if (this.validation.company.chatbot_name === true
        && this.validation.company.chatbot_url === true
        && this.company.status == 'setted'
        ) {
        this.submitIntegrity = true;
      } else {
        this.submitIntegrity = false; 
      }
      console.log(this.submitOn);
    },
     change() {
      this.validChatbotName();
      this.validChatbotURL();
      this.validLengthX();
      this.validLengthY();

      this.checkValidation();
      this.checkValidationIntegrityCode();
     },
     copyCode() {
        console.log('copy');

        var copyText = document.getElementById("code");
        console.log('copyText')
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        this.copied = true;
        setTimeout(()=>{
                this.copied = false;
        },500);
     },
     switchVisible() {
      this.company.chatbot_on = !this.company.chatbot_on;
      this.changeVisibility = true;
      securedAxiosInstance
              .put('toggle_visibility/', {
                account: this.company,
              })
              .then(response => {
               console.log(response);
               //this.loading = false;
               //this.fetch();
               //this.initialize();
               //this.updateSequences();

               setTimeout(()=>{
                this.changeVisibility = false;
                },500);
              })
              .catch(error => {
               console.log(error);
               this.company.chatbot_on = !this.company.chatbot_on;
               this.changeVisibility = false;
               //this.loading = false;
               //this.fetch();
            });

      
     },
     checkIntegrity() {
        this.loadingIntegrity = true;
        securedAxiosInstance.post('/check_integrity')
       .then(response => {
         console.log(response.data.status === 'ok');
         if (response.data.status === 'ok') {
          this.integrated = true;
          console.log(this.integrated);
          this.loadingIntegrity = false;
        } else {
          this.integrated = false;
          this.loadingIntegrity = false;
        }
       }).catch(error => {
               console.log(error);
               //this.loading = false;
                this.integrated = false;
                this.loadingIntegrity = false;
        });
     },
     fetch() {
      this.loading = true;
      securedAxiosInstance.get('/account')
       .then(response => {
         console.log(response.data.data);
         this.company = response.data.data.account;
         this.user = response.data.data.user;
         this.loading = false;
         this.change();
         this.computedIntegrationCode();
         this.setWidgetPositionHelper();
         
         if ( this.oldStatus == 'completed' && this.company.status == 'setted') {
                //redirect to settings for update
                console.log('emit clicked')
                Navigation.$emit('updateStatusAccount', 'setted-code');
                Navigation.$emit('clicked', 'settings');
                const element = document.getElementById("code_section");
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
             }
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.company.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },
     uploadLogo() {
        this.loading = true;
        const formData = new FormData();
          
        formData.append('logo', this.company.logo)

        securedAxiosInstance.post('/upload_logo', formData)
        .then(response => {
               console.log(response);
               this.loading = false;
               
               EventBus.$emit('account-reload', 'reload');
               this.fetch();
               //this.initialize();
               //this.updateSequences();
              })
              .catch(error => {
               console.log(error);
               this.loading = false;
               this.fetch();
            });
        
        }, 

     selectedLogo(event) {
          this.company.logo = event.target.files[0];
          //this.$set(this.company, 'logo', event.target.files[0] );
          console.log('changed image');
          console.log(this.company.logo);
        },



     update() {
      this.loading = true;
      this.oldStatus = this.company.status;
      securedAxiosInstance
              .put('settings/', {
                account: this.company,
              })
              .then(response => {
               console.log(response);
               this.loading = false;
               this.fetch();
               //this.initialize();
               //this.updateSequences();
              })
              .catch(error => {
               console.log(error);
               this.loading = false;
               this.fetch();
            });
     },
     computedIntegrationCode() {
      this.integrationCode = this.integrationCodeTemplate.replace('API_KEY', this.company.api_key);
     },

     calcWidgetPosition() {
        this.company.chatbot_widget_position = `${this.widgetPositionHelper.align}: ${this.widgetPositionHelper.length_x}px; bottom: ${this.widgetPositionHelper.length_y}px;`;
      },

     setWidgetPositionHelper() {
        let arr_text = this.company.chatbot_widget_position;
        //console.log(arr_text);
        let align_position = arr_text.match(/(?<align>left|right): (?<length_x>\d+)px; bottom: (?<length_y>\d+)px;/);
        //console.log(align_position);
        this.widgetPositionHelper = align_position.groups;
        //console.log(align_position.groups);
        //console.log('selected');
        //console.log(document.querySelector('input[type="radio"]:checked').value);
        //console.log(document.getElementById(this.widgetPositionHelper.align));
        document.getElementById(this.widgetPositionHelper.align).click();
        //console.log(document.getElementById(this.widgetPositionHelper.align));

        setTimeout(() => {
         document.getElementById(this.widgetPositionHelper.align).click();
         //alert('ok');
        }, 50);
      },
    setColorScheme(color) {
      if (color === null){
        this.company.chatbot_color_scheme = null;
      } else {
        this.company.chatbot_color_scheme = color;
      }
    },
    exportBackup() {
      this.loadingBackup = true;
      this.backupAction = 'export';
      this.backupSuccess = null;
      this.backupError = null;
      securedAxiosInstance.get('/export_backup')
       .then(response => {
         console.log(response.data.data);
         
         this.backupDownloadURL = response.data.data.backup_url
         this.downloadBackup();
       }).catch(error => {
           console.log(error);
           this.loadingBackup = false;
           this.backupAction = null;
           this.backupDownloadURL = null;
        });
    },
    downloadBackup() {
      this.backupAction = 'downloading';
      axios({
        url: this.backupDownloadURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
           var fileURL = window.URL.createObjectURL(new Blob([response.data]));
           var fileLink = document.createElement('a');
        
           fileLink.href = fileURL;
           fileLink.setAttribute('download', 'assis_backup.txt');
           document.body.appendChild(fileLink);
         
           fileLink.click();
           this.backupAction = null;
           this.loadingBackup = false;
           this.backupError = null;
           this.backupSuccess = "Plik z backupem został automatycznie pobrany"
      });
    },
    selectedBackup(event) {
          this.backupFile = event.target.files[0];
          //this.$set(this.company, 'logo', event.target.files[0] );
          console.log('changed backup');
           this.backupSuccess = null;
           this.backupError = null;
          //console.log(this.company.logo);
    },

    importBackup() {
      this.loadingBackup = true;
      this.backupAction = 'import';

        const formData = new FormData();  
        formData.append('backup_file', this.backupFile)

      securedAxiosInstance.post('/import_backup', formData)
       .then(response => {
         console.log(response.data.data);
         this.loadingBackup = false;
         this.backupAction = null;
         this.backupSuccess = "Import danych został zakończony sukcesem";
         this.backupError = null;         
       }).catch(error => {
           console.log(error);
           this.loadingBackup = false;
           this.backupAction = null;
          this.backupSuccess = null;
         this.backupError = "Wystąpił błąd podczas importu danych";    
        });
    },
  },
     
 };
 //alert('account');

 </script>

 <style scoped>
input[type=text]:not(.browser-default) {
  box-sizing: border-box !important;
}

.input-field .mar  {
  margin-bottom: 12px !important;
}

.file-field {
  margin: 0 !important;
}

.panellog {
max-width: 800px;
margin: 0 auto;
  }

.mar-bot-10 {
  margin-bottom:  10px;
}

[type="radio"] + span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: .28s ease;
        transform: scale(1.02);
}

.pad10 {
  padding: 10px;
}

.select_color_scheme {
  border-radius: 6px;
  width: calc(100% - 20px);
  height: 40px;
  background: linear-gradient(48deg,#0277bd,35%,#0d47a1 100%);
  padding: 10px;
  cursor: pointer;
}

.red_scheme {
  background: linear-gradient(48deg, #E21515, 35%, #7A0808 100%) !important;
}

.green_scheme {
  background: linear-gradient(48deg, #39E408, 35%, #336C22 100%) !important;
}

.beige_scheme {
  background: linear-gradient(48deg, #745A3D, 35%, #472105 100%) !important;
}

.select_color_scheme.selected {
  border: 3px solid orange;
  cursor: default;
}
 </style>





<template>
	<footer class="footer"> 
    <div class="nav-wrapper sec-text">
     <div class="valign-wrapper primary-text">
       {{brand_logo}}&trade; &copy; {{message}} by Data Method Sp. z o.o. 2021
    </div>
      
      
    </div>
  </footer>
</template>

<script>
 export default {
   name: 'LayFooter',
   props: {
    message: String,
   },
   data () {
    return {
      brand_logo: 'AssisBot',
    };
  },
 };

</script>

<style scoped>
  .nav-wrapper {
    padding: 12px 10px;
  }
</style>
<template>
  <div><a @click="signout();" class="collection-item"><i class="material-icons left">exit_to_app</i>Wyloguj się</a></div>
</template>

<script>
import { securedAxiosInstance } from '../backend/axios/index.js'
 export default {
   name: 'account',
   data: () => ({
     account: [],
     user: null
   }),

   methods: {
         signout () {
          securedAxiosInstance
              .delete('/signin')
              .then(response => {
               console.log(response);
               //this.initialize();
                 delete localStorage.csrf
                 delete localStorage.signedIn
                //this.$router.replace('/')
                //this.$emit("authenticated", false);
                this.$router.push({ name: "signin" }).catch(()=>{});
              })
              .catch(error => {
               console.log(error);
            });
     },
     forceSignOut() {
        delete localStorage.csrf
        delete localStorage.signedIn
        this.$router.replace('/')
        //this.$emit("authenticated", false);
        //this.$router.push({ name: "signin" }).catch(()=>{});
     }
   }
 };
 //alert('account');
 </script>

 <style>
 .collection-item:hover {
  cursor:pointer;
 }
 </style>
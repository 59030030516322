<template>
  
    <LayLayout></LayLayout>
  
</template>

<script>
//import axios from 'axios';
import LayLayout from './layouts/LayLayout.vue'
import LayHeader from './layouts/LayHeader.vue'
import account from './account.vue'
//import Login from './packs/components/login.vue';
//import NavigationLink from './packs/components/NavigationLink.vue'

export default {
   name: 'Secure',
  data: function () {
    return {
      message: "Hello Vue!",
    }
  },
  
  components: {
    LayLayout,
    LayHeader,
    account,
    //Login
    //NavigationLink
  }
}

</script>


<template>
    <div id="login" class="z-depth-2" v-on:keyup.enter.prevent="reset()">
        <div class="panellog">
          <div class="light-blue  darken-4 white-text headlog center-align"><h4>Reset hasła</h4></div>
          
          <div v-show="loading">
            <div class="progress margin0">
              <div class="indeterminate"></div>
            </div>
          </div>
          <div v-show="!success">
            <div class="input-field mar">
                <input id="password" autocomplete="off" type="password" v-model="password" v-on:keyup="change()" />
                <label for="password" class="">Nowe hasło</label>
            </div>
            <div class="row mar">
              <b>Hasło musi zawierać:</b><br>
              <div>
                <span v-if="validation.upperCase" class="green-text">
                <i class="material-icons left tiny green-text">check_circle</i> Minimum jedna dużą literę
                </span>
                <span v-else class="red-text">
                  <i class="material-icons left tiny red-text">cancel</i> Minimum jedna dużą literę
                </span>
              </div>
              <div>
                <span v-if="validation.number" class="green-text">
                <i class="material-icons left tiny green-text">check_circle</i> Minimum jedną cyfrę
                </span>
                <span v-else class="red-text">
                  <i class="material-icons left tiny red-text">cancel</i> Minimum jedną cyfrę
                </span>
              </div>
              <div>
                <span v-if="validation.specialChar" class="green-text">
                <i class="material-icons left tiny green-text">check_circle</i> Minimum jeden znak specjalny
                </span>
                <span v-else class="red-text">
                  <i class="material-icons left tiny red-text">cancel</i> Minimum jeden znak specjalny
                </span>
              </div>
              <div>
                <span v-if="validation.minLength" class="green-text">
                <i class="material-icons left tiny green-text">check_circle</i> Minimum 8 znaków
                </span>
                <span v-else class="red-text">
                  <i class="material-icons left tiny red-text">cancel</i> Minimum 8 znaków
                </span>
              </div>


            </div>
            <div class="input-field mar">
                <input v-model="password_confirmation" type="password" id="password_confirmation" v-on:keyup="checkPasswordConfirmation()" />
                <label for="password_confirmation" class="">Powtórz nowe hasło</label>
            </div>

            <div class="mar">
                <span v-if="validation.passwordConfirmation == true " class="green-text">
                <i class="material-icons left tiny green-text">check_circle</i> Podane hasła zgadzają się
                </span>
                <span v-else-if="(validation.passwordConfirmation == false && password_confirmation.length > 0)" class="red-text">
                  <i class="material-icons left tiny red-text">cancel</i> Podane hasła nie są identyczne.
                </span>
              </div>

            <div class="center-align">
              <transition name='fade'>
                <span class="red-text" v-if="error">{{ error }}</span>
              </transition>
            </div>
            <p class="center-align mar-">
                    <button type="button" v-bind:class="this.submitOn === true ? '' : 'disabled'" class="btn" v-on:click.prevent="reset()">Zmień hasło</button>
            </p>

            <div class="center-align mar">
              <div class="grey-text text-darken-2"> Masz już konto?</div>
              <router-link to="/">Zaloguj się</router-link><br><br>

              <div class="grey-text text-darken-2"> Nie masz jeszcze konta?</div>
              <router-link to="/zaloz-konto">Zarejestruj się</router-link>
            </div>
          </div>
          <div class="center-align mar" v-show="success">
              <span class="blue-text">Twoje hasło zostało zmienione. Przejdź do strony logowania i&nbsp;zaloguj się używając nowego hasła.</span><br><br>
               <router-link to="/" class="btn">Logowanie</router-link>
          </div>
          
      </div>
    </div>

</template>

<script>
import { plainAxiosInstance } from '../backend/axios/index.js'
export default {
  name: 'ResetPassword',
  data () {
    return {
      password: '',
      password_confirmation: '',
      error: '',
      notice: '',
      validation: {
        upperCase: false,
        number: false,
        specialChar: false,
        minLength:false,
        passwordConfirmation: null,
      },
      submitOn: false,
      loading: false,
      success: null,

    }
  },
  created () {
    this.checkPasswordToken()
  },
  methods: {
    checkUpperCase() {
      if (this.password.toLowerCase() != this.password) {
        this.validation.upperCase = true;
      } else {
        this.validation.upperCase = false;
      }
    },
    checkNumber() {
      if (/\d/.test(this.password)) {
        this.validation.number = true;
      } else {
        this.validation.number = false;
      }
    },
    checkSpecialChars() {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if(format.test(this.password)){
        this.validation.specialChar = true;
      } else {
        this.validation.specialChar = false;
      }
    },
    checkMinLength() {
      if (this.password.length >= 8) {
        this.validation.minLength = true;
      } else {
        this.validation.minLength = false;
      }
    },
    checkPasswordConfirmation() {
      if (this.password === this.password_confirmation) {
        this.validation.passwordConfirmation = true;
      } else {
        this.validation.passwordConfirmation = false;
      }
      this.checkValidation();
    },
    change() {
      this.checkUpperCase();
      this.checkNumber();
      this.checkSpecialChars();
      this.checkMinLength();
      this.checkPasswordConfirmation();
      this.checkValidation();
    },
    checkValidation() {
      if (this.validation.upperCase === true
        && this.validation.number === true
        && this.validation.specialChar === true
        && this.validation.minLength === true
        && this.validation.passwordConfirmation === true
        //&& this.error === '' // 
        ) {
        this.submitOn = true;
      } else {
        this.submitOn = false; 
      }
      console.log(this.submitOn);
    },
    reset () {
      if (this.submitOn === true) {
        this.loading = true;
        plainAxiosInstance.patch(`/reset-hasla/${this.$route.params.token}`, { password: this.password, password_confirmation: this.password_confirmation })
        .then(response => this.resetSuccessful(response))
        .catch(error => this.resetFailed(error))
      }
    },
    resetSuccessful (response) {
      this.notice = 'Your password has been reset successfully! Please sign in with your new password.'
      this.error = ''
      this.password = ''
      this.password_confirmation = ''
      this.success = true;
      this.loading = false;
    },
    resetFailed (error) {
      this.error = (error.response && error.response.data && error.response.data.error) || 'Nieznany błąd'
      this.notice = ''
      this.loading = false;
    },
    checkPasswordToken () {
      plainAxiosInstance.get(`/reset-hasla/${this.$route.params.token}`)
        .catch(error => {
          this.resetFailed(error)
          this.error = "Ten link został już wykorzystany lub przedawniony";
          //this.$router.replace('/')
        })
    }
  }
}
</script>

<style scoped>
    #login {
        width: 500px;
        //border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
         position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
    }
    .panellog {
      margin:0;
      overflow-y: auto;
    max-height: 90vh;
    }
    .mar {
      margin: 40px;
    }

    .mar input {
      width:100%;
      box-sizing: border-box;
    }
    .headlog {
    
    }

    h4 {
      margin: 0;
      padding:10px;
    }
</style>
<template>
 <div>
      <div class="message-container center-align">

        <span class="hide">
          {{activeSequence.name}}
        </span>
       
        <div class="container_border-">
          <div>

            <div v-if="this.sequences.length">
                      <div>
                        <div class="center-align grey-text text-darken-2">Sekwencje ({{this.sequences.length}})</div>
                        <draggable  v-model="sequences" ref="draggable_seq"
                                          
                                            class="list-group"
                                            handle=".handle"
                                            ghost-class="hidden-ghost"
                                            v-bind="dragOptions"
                                            :move="checkMove"
                                            @start="drag = true"
                                            @end="drag = false"
                                            @update="getOrders()"
                                            @remove="getOrders()">
                          <transition-group :name="!drag ? 'flip-list' : 'flip-list' ">
                              <div v-for="sequence in sequences"
                                   :key="sequence.seq_ord"
                                   class="list-group-sequence"
                                   > 
                                  
                                  <div v-if="edit_id != sequence.id" class='message sequence-bg grey- lighten-4-'>{{sequence.name}}
                                     <div class="message-overlay hide-">
                                       <div class="row margin0">
                                        <div class="col s4">
                                          <span class="message-btn tooltipped handle" data-position="bottom" data-tooltip="Edytuj">
                                            <i class="material-icons">drag_handle</i>
                                          </span>
                                        </div>

                                        <div class="col s4">
                                          <span @click.stop.prevent="editsequence(sequence)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Edytuj">
                                            <i class="material-icons">edit</i>
                                          </span>
                                        </div>
                                        <div class="col s4">
                                          <span @click.stop.prevent="deletesequence(sequence)" class="message-btn delete tooltipped" data-position="bottom" data-tooltip="Usuń">
                                            <i class="material-icons">delete</i>
                                          </span>
                                        </div>
                                       </div>
                                     </div>
                                  </div>
                                  <div v-else>
                                    
                                    <div v-if="action == 'edit'">
                                        <div class="input-field col s12 input-pad" >
                                          <textarea v-model="sequence.name" id="textarea1" class="materialize-textarea message grey lighten-4" autocomplete="off"></textarea>
                                            <transition name="fade-slide">
                                              <span v-if="error" class="center-align red-text">{{error}}</span><br>
                                            </transition>
                                          <button @click="cancel();" class="btn grey left">Anuluj</button>
                                          <button @click="save(sequence);" class="btn right">Zapisz</button>
                                        </div>
                                    </div>
                                    <div v-else-if="action == 'delete'">
                                          <div class='message grey lighten-4 to_delete'>{{sequence.name}}</div>
                                          <div class="input-field col s12 input-pad button_area_inlne" >
                                            <span class="center-align grey-text text-darken-2">Potwierdź usunięcie</span><br>
                                            <button @click="cancel();" class="btn grey left">Anuluj</button>
                                            <button @click="destroysequence(sequence);" class="btn red right">Usuń</button>
                                          </div>

                                    </div>
                                  </div>
                              </div>
                          </transition-group>
                        </draggable>
                      </div>
            </div>
            <div v-else>
            <div>
              <span class="grey-text text-darken-2"> Brak sekwencji</span><br>
              <i class="large material-icons grey-text text-lighten-2">view_stream</i>
              
            </div>
          </div>

            <div v-if="this.action == 'new'">
                <div class="input-field col s12 input-pad" >
                  <textarea v-model="sequence.name" id="textarea1" class="materialize-textarea message grey lighten-4" autocomplete="off"></textarea>
                    <transition name="fade-slide">
                      <span v-if="this.error" class="center-align red-text">{{this.error}}</span><br>
                    </transition>
                  <button @click="cancel();" class="btn grey left">Anuluj</button>
                  <button @click="save(sequence);" class="btn right">Zapisz</button>
                </div>
            </div>
            <div v-else>
              <div class="button_area_inline">
                 <a @click.stop.prevent="newsequence();" class="btn-floating waves-effect waves-light"
                 v-bind:class="{'pulse' : !this.sequences.length }">
                  <i class="material-icons">add</i></a>
              </div>  
            </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { securedAxiosInstance} from '../backend/axios/index.js'
 import draggable from 'vuedraggable'
 export default {
   name: 'sequence_sequences',
   display: "Simple",
  order: 0,
   components: {
     draggable,
   },
   props: {
    sequencesProp: Array,
    activeSequence: Object,
    },

   data: function(){
          return {
    loading: false,
    action: null,
    edit_id: null,
    sequences: this.sequencesProp,
    sequence: {
      id: null,
      name: null,
      parent_id: null,
      seq_ord: null,
      },
    originalsequence: {},
    enabled: true,
    drag: false,
    orders: [],
    error: null,
    }
   },

   mounted: function () {
     //this.fetchSequences();
     //this.sequences = sequences;
     //this.setsequences();
     this.setsequences();
     console.log('mounted-seq');
   },
   beforeMount() {
    //this.setsequences();
      
  },
  beforeUpdate() {
    //this.setsequences();
  },
  updated(){
   //setTimeout(()=>{
   this.setsequences();
   console.log('updt-seq');
//},1000);
  },

   methods: {
          setsequences() {
            this.sequences = this.sequencesProp;
            console.log('updated sequences = this.sequencesProp');
          },
          newsequence() {
          //this.newMessage = true;
          this.action = "new";
          this.sequence = {
              name: null,
              seq_ord: this.sequences.length + 1,
            }
          },
          editsequence(sequence) {
            this.action = "edit";
            console.log('edit');
            console.log(this.sequence.id);
            this.edit_id = sequence.id;
            this.originalsequence = Object.assign({}, sequence);
            this.sequence = Object.assign({}, sequence);
            this.error = null;
            //this.newMessage = false;
            //this.confirmDestroy = false;
            //this.deletedIndex = -1;
          },
          validation(name) {
            console.log('name:');
            console.log(name);
            if (name && /([^\s]+)/.test(name.trim())) {
              this.error = null;
              console.log('sth');
            } else {
              this.error = "Nazwa sekwencji jest wymagana";
              console.log('null');
            }
          },
          save(sequence) {
            this.validation(sequence.name);
            if (this.error == null) {
              if (this.action == 'edit') {
                securedAxiosInstance
                  .put(`sequences/${sequence.id}`, {
                    sequence: {
                      id: sequence.id,
                      name: sequence.name, 
                    },
                  })
                  .then(response => {
                   console.log(response);
                   this.reloadSequence();
                   this.action = null;
                   this.edit_id = null;
                  })
                  .catch(error => {
                   console.log(error);
                });
              } else {
                 securedAxiosInstance
                  .post('sequences/', {
                     sequence:  {
                     name: sequence.name ,
                     parent_id: this.activeSequence.id,
                     seq_ord: sequence.seq_ord,
                     },
                   })
                   .then(response => {
                     //console.log(response);
                     console.log("Created!");
                     this.reloadSequence();
                     //this.sequences.push({}, sequence);
                     //this.initialize();
                     //this.reloadSequence(); //for rerender sequences
                     this.action = null;
                     this.edit_id = null; 
                     //this.sequences = null;
                     //this.reloadSequence();
                     
                   })
                   .catch(error => {
                     console.log(error);
                 });

              }
              //this.close();
              //this.editedIndex = -1;
              //this.sequence = Object.assign({}, null);
              
              //this.newMessage = false;
              //this.action = "message";
            }
          },
          updateOrders() {
            //const index = this.sequences.indexOf(sequence);
            //confirm("Are you sure you want to delete this sequence?");
            //console.log('destroy');
            //console.log(index) ;


            securedAxiosInstance
              .put(`sequences/${this.activeSequence.id}/update_orders`, {
                sequence_items: this.orders,
                 })
              .then(response => {
                console.log(response);
                console.log(response.data.json);
                this.reloadSequence();
                this.action = null;
                this.edit_id = null; 
                //alert(response.data.json);
                //this.initialize();
                //this.updateSequenceSeqs(); //for rerender sequences
              })
              .catch(error => {
                console.log(error);
              });
              //console.log(sequences) ;
              //this.sequences.splice(index, 1);
              //console.log(sequences) ;
          

        },
          deletesequence(sequence) {
            this.action = "delete";
            //console.log('delete');
            this.edit_id = sequence.id;
            //this.originalsequence = Object.assign({}, sequence);
            //this.sequence = Object.assign({}, sequence);
          },
          destroysequence(sequence) {
            const index = this.sequences.indexOf(sequence);
            //confirm("Are you sure you want to delete this sequence?");
            console.log('destroy');
            console.log(index) ;
            securedAxiosInstance
              .delete(`sequences/${sequence.id}`)
              .then(response => {
                console.log(response);
                console.log(response.data.json);
                this.reloadSequence();
                this.action = null;
                this.edit_id = null; 
                this.sort();
                //alert(response.data.json);
                //this.initialize();
                //this.updateSequenceSeqs(); //for rerender sequences
              })
              .catch(error => {
                console.log(error);
              });
              //console.log(sequences) ;
              
              //console.log(sequences) ;
          },
          cancel() {
            //this.editedIndex = -1;
            
            //this.sequence = Object.assign({}, this.originalsequence);
            if (this.action == 'edit') {
              this.sequences.find( el => el.id === this.edit_id).name = this.originalsequence.name;
            }
            //this.sequences = Object.assign({}, this.originalsequence);
            //this.newMessage = false;
            //this.confirmDestroy = false;
            //this.deletedIndex = -1;
            //this.action = "message";
            //this.editedSubsequenceIndex = -1;
            //this.editedSubsequence = Object.assign({}, null);
            this.action = null;
            this.edit_id = null;
            this.error = null;
          },
          reloadSequence() {
            this.$emit('updateSequenceSeq', true);
          },
          checkMove: function(e) {
             
             let orders_arr = [];
             window.console.log("Future index: " + e.draggedContext.futureIndex);
             window.console.log("Future index: " + e.draggedContext.element.id);
             //let order_sequence = {id: e.draggedContext.element.id, index: e.draggedContext.futureIndex }
             //orders_arr.push(order_sequence);
             //this.orders = orders_arr;
              //console.log(order_sequence);
             //console.log(orders_arr);
             //console.log(e.relatedContext.component._props.value);
          },

         sort() {
              this.sequences = this.sequences.sort((a, b) => a.order - b.order);
            },
          getOrders: function() {
            console.log('getOrders');
            let sequencesArr = (JSON.parse(JSON.stringify(this.$refs.draggable_seq.realList)));
            const sequencesOrders = sequencesArr.map((sequence, index) => {
                const container = {};

                container.id = sequence.id;
                container.seq_ord = index + 1; //re-sort index

                return container;
            });
            console.log(sequencesArr);
            console.log(sequencesOrders);
            this.orders = sequencesOrders;
            this.updateOrders();
          },


    },
    computed: {
    draggingInfo() {
      return this.drag ? "under drag" : "";
    },
    dragOptions() {
      return {
        animation: 200,
        //group: "description",
        disabled: false,
        //ghostClass: "ghost"
      };
    },
     
  },
  watch: {
    // whenever question changes, this function will run
    //sequences: function (newQuestion, oldQuestion) {
      //this.answer = 'Waiting for you to stop typing...'
      //this.debouncedGetAnswer()
      //alert('new');
    //}
  },
  

}


 </script>

 <style>
.sequence-bg {
  background-color: #0277bd;
  color: white;
  border-radius: 5px;
    background: #0d47a1;
}
.sequence-bg:hover {
  background: #0d47a1;
}

textarea.message {
    width: 100%;
    clear: both !important;
    float: none !important;
    height: 3rem !important;
    padding: 10px !important;
    line-height: 1.4rem;
    margin: 0 !important;
    box-sizing: border-box;
    display: inline-block;
    height: 1.4rem;
    font-size: 16px;
    min-height: 3rem !important
}

textarea.materialize-textarea:focus:not([readonly]) {
    border: 2px solid #0277bd;
}
.input-pad {
  padding: 10px;
  margin-top: 0;
}

.input-field {
  margin-bottom: 34px !important;
}


.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}


.list-group-sequence {
  cursor: move;
}
.list-group-sequence i {
  cursor: pointer;
}

.hidden-ghost {
  //opacity: 1;
  //visibility: hidden;
  //position: absolute;
  //top: 0;
  //left: 0;
  //visibility: hidden;
  //display:  none;
  background-color: transparent;
}
 </style>

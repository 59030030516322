<template>
    <div>
    
    <div v-show="loading">
            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div v-show="!loading">
        <!-- request finished -->

     <h1>Start</h1>
      <div class="diagram white pad15">
        
      <div class="row">
        <div class="col s4">
              <div class="center-align">
                <transition name="fade">
                <div v-if="integrated" class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium green-text text-darken-3">check_circle</i><br>Twoja strona jest poprawnie zintegrowana z AssisBot&trade;</div>
                </div>
                <div v-else class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium red-text text-darken-3">error</i><br>Upewnij się, że kod integracyjny został poprawnie umieszczony na twojej stronie www.</div>
                </div>
                </transition>
                <transition>
                      <div v-show="loadingIntegrity">
                            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                      <div class="progress">
                        <div class="indeterminate"></div>
                      </div>
                    </div>
                </transition>
              </div>
        </div>

        <div class="col s4">
          <div class="center-align">
          <div v-if="account.chatbot_on" class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium blue-text text-darken-3">visibility
                </i><br>Chatbot jest widoczny na twojej stronie</div>
                </div>
                <div v-else class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium grey-text text-darken-1">visibility_off
                </i><br>Chatbot jest ukryty na twojej stronie</div>
                </div>
              </div>
        </div>
            <div class="col s4">
              <div class="center-align">
                <transition name="fade">
                <div v-if="databases.length > 0" class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium blue-text text-darken-3">dns</i><br>Zintegrowane bazy danych: <b>{{databases.length}}</b></div>
                </div>
                <div v-else class="card-panel panel-inline grey lighten-4 grey-text text-darken-2 mar-bot-10 valign-wrapper">
                    <div><i class="material-icons  medium grey-text text-darken-1">dns</i><br>Brak zintegrowanych baz danych.</div>
                </div>
                </transition>
                <transition>
                      <div v-show="loadingIntegrity">
                            <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                      <div class="progress">
                        <div class="indeterminate"></div>
                      </div>
                    </div>
                </transition>
              </div>
        </div>
      </div>
      
      
    </div>
  </div>
  <div id="assis_app"></div>
  <div id="assis_app_id" :data-code="'0410423812764484'"></div>

</div>

</template>

<script>
//import draggable from "vuedraggable";
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js'
export default {
  name: "start",
  data() {
    return {
      loading: false,
      integrated: false,
      loadingIntegrity: false,
      databases: Array,
      db_loading: false,
      account: {chatbot_on: false},
      chatbotPreview: true,
    };
  },
  methods: {
    fetch() {
      this.loading = true;
      securedAxiosInstance.get('/account')
       .then(response => {
         console.log(response.data.data);
         this.account = response.data.data.account;
         //this.user = response.data.data.user;
         this.loading = false;
          let assisBotScript = document.createElement('script')
          assisBotScript.setAttribute('src', 'https://api.assisbot.pl/api.js')
          document.body.appendChild(assisBotScript);
         //this.change();
         //this.computedIntegrationCode();
         //console.log(this.account.status);
         //this.$emit('accountStatus', this.company.status);
       }).catch(error => {
               console.log(error);
               this.loading = false;
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });

     },
     checkIntegrity() {
        this.loadingIntegrity = true;
        securedAxiosInstance.post('/check_integrity')
       .then(response => {
         console.log(response.data.status === 'ok');
         if (response.data.status === 'ok') {
          this.integrated = true;
          console.log(this.integrated);
          this.loadingIntegrity = false;
        } else {
          this.integrated = false;
          this.loadingIntegrity = false;
        }
       }).catch(error => {
               console.log(error);
               //this.loading = false;
                this.integrated = false;
                this.loadingIntegrity = false;
        });
     },
  db_databases() {
      this.db_loading = true;
      console.log('DBs');
      securedAxiosInstance.get('/external_databases')
       .then(response => {
         console.log(response.data.data);
         //this.account = response.data.data.account;
         this.databases = response.data.data;
         console.log('DBs2');
         console.log(this.databases);
         //this.user = response.data.data.user;
         this.db_loading = false;
         //this.change();
         
       }).catch(error => {
               console.log(error);
               this.db_loading = false;

        });

     },
   },
  created: function() {
        this.fetch();
        this.db_databases();
        //window.assisAppId = "0410423812764484";
        
        //var assisAppId = "0410423812764484";

  },
  mounted: function() {
      //if (this.chatbotPreview === true) {
        
          
        //var head = document.getElementsByTagName('head')[0];
        //  var body = document.getElementsByTagName('body')[0];
        //var div = document.getElementById("assis_app");

        //div.id = "assis_app";
        //body.append(div);

        //var style = document.createElement('link');
        //style.href = '../assisbot-customer/dist/css/app.f056746e.css';
        //style.href = 'https://api.assisbot.pl/app.css';
        //.type = 'text/css';
        //style.rel = 'stylesheet';
        //style.media= 'screen,projection';
        //head.append(style);

          //var script = document.createElement('script');
        //script.src = '../assisbot-customer/dist/js/app.c66ab85a.js';
          //script.src = 'https://api.assisbot.pl/app.js';
          //script.type = 'text/javascript';
          //body.append(script);

      //}        const assisAppId = this.account.api_key;
        console.log('api_key');
        console.log(this.account.api_key);
        window.assisAppId = this.account.api_key;

        console.log('window');
        console.log(assisAppId);
       
        
        this.fetch();
        this.checkIntegrity();
        this.db_databases();
  },
};
</script>




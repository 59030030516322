<template>
	 <header class="header">
     <nav>
      <div class="nav-wrapper">
        <a href="#" class="brand-logo">{{brand_logo}}&trade; </a>
        <ul id="nav-mobile" class="right hide-on-med-and-down hide">
          <li><a href="sass.html">Link1</a></li>
          <li><a href="badges.html">Kolejny link</a></li>
          <li><a href="collapsible.html">Ostatni link</a></li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
 export default {
   name: 'LayHeader',
   props: {
    message: String,
   },
   data () {
    return {
      brand_logo: 'AssisBot',
    };
  },
 };

</script>

<style scoped>
  .nav-wrapper {
    padding: 0 10px;
  }
  nav {
        box-shadow: none;
  }
</style>
<template>
  <div class="wrapper">
      <LayHeader :message="message"></LayHeader>
      <LayMain></LayMain>
      <LaySidebar></LaySidebar>
      <aside class="aside aside-2 hide">Aside 2</aside>
      <LayFooter></LayFooter>
  </div>
</template>

<script>
import LayHeader from './LayHeader.vue'
import LaySidebar from './LaySidebar.vue'
import LayFooter from './LayFooter.vue'
import LayMain from './LayMain.vue'

export default {
  name: "LayLayout",
  data () {
    return {
      message: 'Parent text',
    };
  },
  components: {
    LayHeader,
    LaySidebar,
    LayFooter,
    LayMain,
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;  
  flex-flow: row wrap;
  //font-weight: bold;
  //text-align: center; 
  align-items: stretch;
}

.wrapper > * {
  //padding: 10px;
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}

.header {
  //background: tomato;
  border-bottom: 1px solid #e0e0e0;
}

.footer {
  //background: lightgreen;
  border-top: 1px solid #e0e0e0;
}

.main {
  text-align: left;
  //background: deepskyblue;
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 1em 1em;
}

.aside {
  height: calc(100vh - 100px);
  overflow-y: hidden;
  transition: all 300ms ease-in-out;
}

.aside:hover {
  overflow-y: auto;
  flex-basis: 220px !important;
}
.aside.collection .collection-item {
  background-color: transparent;
}

.aside-1 {
 //background: gold;
  flex-basis: 200px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}



.aside-2 {
  background: hotpink;
}

@media all and (min-width: 600px) {
  .aside { flex: 1 0 0; }
}

@media all and (min-width: 800px) {
  .main    { flex: 3 0px; }
  .aside-1 { order: 1; } 
  .main    { order: 2; }
  .aside-2 { order: 3; }
  .footer  { order: 4; }
}
</style>
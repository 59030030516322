<template>
  <div class="center-align">
    <div v-show="account.logo_url">
        <img @load="loadedLogo = true" :src="account.logo_url" style="max-width:148px;" />     
    </div>
    <div v-show="!account.logo_url || !loadedLogo">
      <i class="large material-icons">account_circle</i>
    </div>

    <li ><b> {{account.company_name}}</b> </li>
  </div>

</template>


<script>
import { EventBus } from '../eventbus';
import { securedAxiosInstance, plainAxiosInstance } from '../backend/axios/index.js';
 export default {
   name: 'account',
   data: () => ({
     account: Object,
     loadedLogo: false,
     //user: null
   }),


  created: function () {
    //if (!localStorage.signedIn) {
    //  this.$router.replace('/')
    //} else {
      this.fetchAccount();
      //this.$emit('accountStatus', this.account.status);
    //}
   
  },

  mounted: function() {
    //this.$emit('accountStatus', this.account.status);
    EventBus.$on('account-reload', (value) => {
     if (value === 'reload') {
     //alert('reload') ;
     this.refetchAccount();
      }
    });
  },
   

   methods: {
     fetchAccount () {
       securedAxiosInstance.get('/accounts')
       .then(response => {
         console.log(response.data.data);
         this.account = response.data.data;
         console.log(this.account.status);
         this.$emit('accountStatus', this.account.status);
       }).catch(error => {
               console.log(error);
            console.log('logout account');
            delete localStorage.csrf;
            delete localStorage.signedIn;
            this.$router.replace('/');
            this.csrf = null;
        });
     },
      refetchAccount () {
       securedAxiosInstance.get('/accounts')
       .then(response => {
         console.log(response.data.data);
         this.account = response.data.data;
         console.log(this.account.status);
         //this.$emit('accountStatus', this.account.status);
       }).catch(error => {
            console.log(error);
            //console.log('logout account');
            //delete localStorage.csrf;
            //delete localStorage.signedIn;
            //this.$router.replace('/');
            //this.csrf = null;
        });
     },
     testSignUp () {
      axios
              .post('/api/signup', {
                user: {
                  email: "tyberiusz.grzeskowiak@gmail.com",
                  password: "abc123",
                  password_confirmation: "abc123", 
                },
              })
              .then(response => {
               console.log(response);
               //this.initialize();
               this.user = response
              })
              .catch(error => {
               console.log(error);
            });
     },
          testLogIn () {
      axios
              .post('/api/login', {
                user: {
                  email: "tyberiusz.grzeskowiak@gmail.com",
                  password: "abc123",
                },
              })
              .then(response => {
               console.log(response);
               //this.initialize();
               this.user = response
               let token = response.headers.authorization;
               axios.defaults.headers.common['Authorization'] = token;
               localStorage.setItem( 'auth_token', JSON.stringify(token) );

              })
              .catch(error => {
               console.log(error);
            });
     },
         testLogOut () {
      axios
              .delete('/api/logout', {
                user: {
                  email: "tyberiusz.grzeskowiak@gmail.com",
                },
              })
              .then(response => {
               console.log(response);
               //this.initialize();
               this.user = response
               localStorage.removeItem('auth_token');
               axios.defaults.headers.common['Authorization'] = null;
              })
              .catch(error => {
               console.log(error);
            });
     },
   }
 };
 //alert('account');

 </script>
<template>
 <aside class="aside aside-1 dark-bg-">
<ul id="nav-mobile" class="sidenav- sidenav-fixed-" style="transform: translateX(0px);">
       

        <div class="collection">
          <div class="pad10- grey-text text-darken-2">
            
            
            <account @accountStatus="defaultActionView($event)" ></account>
            <div class="pad10"><i class="material-icons left">web</i>Aplikacja</div>
           
 
         </div>
           <div :is="activeView" class="hide"></div>
          <div v-for="component in componentViews.application">
              <a @click="setView(component)" class="collection-item"
              v-bind:class="[ ( component.view === activeView ? 'active' :''), ( (component.view !== activeView && allowViews.includes(component.view) == false) ? 'disabled_link' : '' ) ]" >
              <i class="material-icons left">{{component.icon}}</i>{{component.name}}
              </a>
          </div>
          <div class="pad10 grey-text text-darken-2 sep">
            <div class="hide"><i class="material-icons left">account_circle</i>Twoje konto</div></div>
          <div v-for="component in componentViews.account">
            <a @click="setView(component)" class="collection-item"
              v-bind:class="[ ( component.view === activeView ? 'active' :''), ( (component.view !== activeView && allowViews.includes(component.view) == false) ? 'disabled_link' : '' ) ]" >
              <i class="material-icons left">{{component.icon}}</i>{{component.name}}
              </a>
          </div>
          <Signout></Signout>
        </div>
        
        
        <li class="no-padding hide">
          <ul class="collapsible collapsible-accordion">
            <li class="bold"><a class="collapsible-header waves-effect waves-teal" tabindex="0">CSS</a>
              <div class="collapsible-body">
                <ul>
                  <li><a href="color.html">Color</a></li>
                  <li><a href="grid.html">Grid</a></li>
                  <li><a href="helpers.html">Helpers</a></li>
                  <li><a href="media-css.html">Media</a></li>
                  <li><a href="pulse.html">Pulse</a></li>
                  <li><a href="sass.html">Sass</a></li>
                  <li><a href="shadow.html">Shadow</a></li>
                  <li><a href="table.html">Table</a></li>
                  <li><a href="css-transitions.html">Transitions</a></li>
                  <li><a href="typography.html">Typography</a></li>
                </ul>
              </div>
            </li>
            <li class="bold"><a class="collapsible-header waves-effect waves-teal" tabindex="0">Components</a>
              <div class="collapsible-body">
                <ul>
                  <li><a href="badges.html">Badges</a></li>
                  <li><a href="buttons.html">Buttons</a></li>
                  <li><a href="breadcrumbs.html">Breadcrumbs</a></li>
                  <li><a href="cards.html">Cards</a></li>
                  <li><a href="collections.html">Collections</a></li>
                  <li><a href="floating-action-button.html">Floating Action Button</a></li>
                  <li><a href="footer.html">Footer</a></li>
                  <li><a href="icons.html">Icons</a></li>
                  <li><a href="navbar.html">Navbar</a></li>
                  <li><a href="pagination.html">Pagination</a></li>
                  <li><a href="preloader.html">Preloader</a></li>
                </ul>
              </div>
            </li>
            <li class="bold"><a class="collapsible-header waves-effect waves-teal" tabindex="0">JavaScript</a>
              <div class="collapsible-body">
                <ul>
                  <li><a href="auto-init.html">Auto Init</a></li>
                  <li><a href="carousel.html">Carousel</a></li>
                  <li><a href="collapsible.html">Collapsible</a></li>
                  <li><a href="dropdown.html">Dropdown</a></li>
                  <li><a href="feature-discovery.html">FeatureDiscovery</a></li>
                  <li><a href="media.html">Media</a></li>
                  <li><a href="modals.html">Modals</a></li>
                  <li><a href="parallax.html">Parallax</a></li>
                  <li><a href="pushpin.html">Pushpin</a></li>
                  <li><a href="scrollspy.html">Scrollspy</a></li>
                  <li><a href="sidenav.html">Sidenav</a></li>
                  <li><a href="tabs.html">Tabs</a></li>
                  <li><a href="toasts.html">Toasts</a></li>
                  <li><a href="tooltips.html">Tooltips</a></li>
                  <li><a href="waves.html">Waves</a></li>
                </ul>
              </div>
            </li>
            <li class="bold"><a class="collapsible-header waves-effect waves-teal" tabindex="0">Forms</a>
              <div class="collapsible-body">
                <ul>
                  <li><a href="autocomplete.html">Autocomplete</a></li>
                  <li><a href="checkboxes.html">Checkboxes</a></li>
                  <li><a href="chips.html">Chips</a></li>
                  <li><a href="pickers.html">Pickers</a></li>
                  <li><a href="radio-buttons.html">Radio Buttons</a></li>
                  <li><a href="range.html">Range</a></li>
                  <li><a href="select.html">Select</a></li>
                  <li><a href="switches.html">Switches</a></li>
                  <li><a href="text-inputs.html">Text Inputs</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
  </aside>
</template>

<script>
import account from '../account.vue'
import YourAccount from '../YourAccount.vue'
import Signout from '../Signout.vue'
import start from '../start.vue'
import sequences from '../sequences.vue'
import settings from '../settings.vue'
import databases from '../databases.vue'
import conversations from '../conversations.vue'
import { Navigation } from '../../navigation'
import { EventBus } from '../../eventbus';

 export default {
   name: 'LaySidebar',
   props: {
    message: String,
   },
   methods: {
    setView: function(component)
    {
      console.log(component.view)
      if (this.allowViews.includes(component.view)) {
        console.log('include');
        this.activeView = component.view;
        Navigation.$emit('clicked', this.activeView); 
        //this.defaultActionView();
      }
    },
    defaultActionView(status) {
      console.log('status');
      console.log(status);
      if (status === 'created') {
        this.activeView = 'YourAccount';
        this.allowViews = this.allowViewsBase['created'];
      } else if (status === 'completed') {
        this.activeView = 'settings';
        this.allowViews = this.allowViewsBase['completed'];
      } else if (status === 'setted-code') {
        this.activeView = 'settings';
        this.allowViews = this.allowViewsBase['setted'];
      } else if (status === 'setted') {
        this.activeView = 'start';
        this.allowViews = this.allowViewsBase['setted'];
      } else {
        this.activeView = 'YourAccount';
        this.allowViews = this.allowViewsBase['created'];

      }
      Navigation.$emit('clicked', this.activeView);
    },
   },

   created: function() {
    //this.defaultActionView();
   }, 
   updated: function() {
    //this.defaultActionView();
    Navigation.$on('updateStatusAccount', (template) => {
     this.defaultActionView(template);
  });
   },
   data () {
    return {
      brand_logo: 'Sidebar',
      activeView: "start",
      componentViews: 
                     {
                      application: 
                      [
                       {view: 'start', name: "Start", icon: 'home'},
                       {view: 'sequences', name: "Sekwencje", icon: 'view_stream'},
                       {view: 'conversations', name: "Konwersacje", icon: 'question_answer'},
                       {view: 'databases', name: "Bazy danych", icon: 'dns' },
                       {view: 'settings', name: "Ustawienia", icon: 'settings'},
                       ],

                     account: 
                      [
                       {view: 'YourAccount', name: "Twoje konto", icon: 'account_circle'},
                      ],
                     
                   },
      allowViews: [],
      allowViewsBase:
                    {
                      created: ['YourAccount'],
                      completed: ['YourAccount', 'settings'],
                      setted: ['start', 'sequences', 'settings', 'conversations', 'databases', 'YourAccount']
                    },
    };
  },
  components: {
    account,
    start,
    sequences,
    Signout,
    YourAccount,
    settings,
    databases,
    conversations,
  }
 };

</script>

<style scoped>
  .nav-wrapper {
    padding: 0 10px;
  }

  .pad10 {
    padding: 10px 20px;
    //text-align: center;
    margin: 0 auto;
  }

  .collection {
    border:none;
  }

  .collection .collection-item::first-child {
    border-top: 1px solid #e0e0e0;
  }

  .collection-item:hover {
    cursor: pointer;
  }

  .sep {
    padding-top:10px;
    border-top: 1px dotted #ddd;
    margin-top:10px;
  }

  .disabled_link {
    cursor: not-allowed !important;
    color: #9d9c9c !important;
  }
</style>
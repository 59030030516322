//import Vue from 'vue'
import Vue from 'vue/dist/vue.js';
import Router from 'vue-router'
import SigninComponent from "../components/Signin.vue"
import SignupComponent from "../components/Signup.vue"
import SecureComponent from "../components/secure.vue"
import ForgotPassword from '../components/ForgotPassword'
import ResetPassword from '../components/ResetPassword'

//Vue.use(VueRouter)
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: {
                name: "signin"
            }
        },
        {
            path: "/logowanie",
            name: "signin",
            component: SigninComponent
        },
        {
            path: "/zaloz-konto",
            name: "signup",
            component: SignupComponent
        },
        {
            path: "/secure",
            name: "secure",
            component: SecureComponent
        },
        {
          path: '/reset-hasla',
          name: 'ForgotPassword',
          component: ForgotPassword
        },
        {
          path: '/reset-hasla/:token',
          name: 'ResetPassword',
          component: ResetPassword
        }
    ]
})

<template>
  <div id="app-vue">
    <router-view />
  </div>
</template>

<script>
  //require("dotenv").config();
//import Login from './packs/components/login.vue';
//import NavigationLink from './packs/components/NavigationLink.vue'
import axios from 'axios';

export default {
  data: function () {
    return {
      message: "Hello Vue!",
      authenticated: false,
      csrf: null,
      test: process.env.VUE_APP_TEST,
    }
  },
  beforeCreate() {
                  //cler data for autologin with custom credentials
  //if(!this.authenticated) {
 //  this.$router.push({ name: 'signin' }).catch(()=>{});
  //  }
    //this.setInterval();
  },

  mounted() {
    this.setAuthenticated();
    this.setCSRFToken();
    //console.log(require("dotenv").config());
    console.log(process.env);
    console.log(process.env.VUE_APP_TEST);
    //console.warn(this.test);

    //this.setInterval();
  },

  updated() {
    //this.setInterval();
  },
  
  methods: {
            setAuthenticated() {
                //this.authenticated = status;
               this.authenticated = (localStorage.signedIn === true);
               if(!this.authenticated) {
                this.$router.push({ name: "signin" }).catch(()=>{});
            }
          },
          setCSRFToken() {
            this.csrf = localStorage.csrf;
          },
          compareCSRFToken() {
            if (this.csrf != localStorage.csrf) {
              console.log('autologout');
              this.autologout();
            } else {
              console.log('signin - ok');
            }
          },
          autologout() {
            delete localStorage.csrf;
            delete localStorage.signedIn;
            this.$router.replace('/');
            this.csrf = null;
          },
          setInterval() {
            //const myInterval = setInterval(this.compareCSRFToken, 10000);
            //console.log('set_interwal');
            const myInterval = setInterval(console.log('set_interwal'), 10000)
          },
  
            
  }
}

  


document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.tooltipped');
    var instances = M.Tooltip.init(elems);
  });

//let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
//axios.defaults.headers.common['X-CSRF-Token'] = token
//axios.defaults.headers.common['Accept'] = 'application/json'

//const auth_token = JSON.parse( localStorage.getItem('auth_token') );
//if (auth_token) {
//  axios.defaults.headers.common["Authorization"] = auth_token; 
//}

//console.log(token);
//console.log(auth_token);
</script>

<style scoped>
#app-vue {
  //border: 2px dashed orange;
}
p {
  font-size: 2em;
  text-align: center;
  color: red;
}
</style>

/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.
//

import Vue from 'vue/dist/vue.js';
import VueRouter from 'vue-router'
import App from '../app.vue'
import Router from './router';
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance } from './backend/axios/index.js'

//let token = JSON.parse( localStorage.getItem('auth_token') );
//if( token ){
//     window.axios.defaults.headers.common['Authorization'] = token;
//}

Vue.config.productionTip = false;
//Vue.use(TurbolinksAdapter);
//Vue.use(Vuelidate);
//Vue.use(VueRouter)
Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})

  document.addEventListener('DOMContentLoaded', () => {
   const app = new Vue({
   	 router: Router,
     securedAxiosInstance,
     plainAxiosInstance,
     render: h => h(App)
   }).$mount()
   document.body.appendChild(app.$el)
    //require("dotenv").config();
   //console.log(app)
  })




 // document.addEventListener('DOMContentLoaded', function() {
 // 	// Get rid of those pesky eslint errors for Materialize-css
 // 	const options = {};
 //	if (typeof M == "undefined") {var M = {}}
 //   var elems = document.querySelectorAll('.tooltipped');
 //   var instances = M.Tooltip.init(elems, options);
 // });
// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })

//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })


//document.addEventListener('DOMContentLoaded', () => {
//    const app = new Vue({
//        render: h => h(NavigationApp)
//    }).$mount()
//
//    document.body.insertBefore(app.$el, document.body.firstChild);
//})
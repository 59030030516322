<template>
  <div>
      <div class="message-container center-align">
        <span v-if="this.activeSequence.parent" class="parent_sequence">
          {{activeSequence.parent.name}}
        </span>
        <div v-if="this.activeSequence.parent" class="vertical_line_cont">
          <div class="vertical_line"></div>
        </div>
        <span class="sequence-active sequence-active-panel">
          {{activeSequence.name}}
        </span>
       
        <div class="container_border">
          <div>

            <div v-if="this.items.length">
                      <div>
                        <div class="center-align grey-text text-darken-2">Wiadomości ({{this.items.length}})</div>

                        <draggable  v-model="items" ref="draggable"
                                          
                                            class="list-group"
                                            handle=".handle"
                                            ghost-class="hidden-ghost"
                                            v-bind="dragOptions"
                                            :move="checkMove"
                                            @start="drag = true"
                                            @end="drag = false"
                                            @update="getOrders()"
                                            @remove="getOrders()">
                          <transition-group :name="!drag ? 'flip-list' : 'flip-list' ">
                              <div v-for="item in items"
                                   :key="item.item_ord"
                                   class="list-group-item"
                                   > 
                                  
                                  <div v-if="edit_id != item.id" class='message grey lighten-4'>
                                    <div v-html="item.content">{{item.content}}</div>
                                     <div class="message-overlay hide-">
                                       <div class="row margin0">
                                        <div class="col s4">
                                          <span class="message-btn tooltipped handle" data-position="bottom" data-tooltip="Edytuj">
                                            <i class="material-icons">drag_handle</i>
                                          </span>
                                        </div>

                                        <div class="col s4">
                                          <span @click.stop.prevent="editItem(item)" class="message-btn tooltipped" data-position="bottom" data-tooltip="Edytuj">
                                            <i class="material-icons">edit</i>
                                          </span>
                                        </div>
                                        <div class="col s4">
                                          <span @click.stop.prevent="deleteItem(item)" class="message-btn delete tooltipped" data-position="bottom" data-tooltip="Usuń">
                                            <i class="material-icons">delete</i>
                                          </span>
                                        </div>
                                       </div>
                                     </div>
                                  </div>
                                  <div v-else>
                                    
                                    <div v-if="action == 'edit'">
                                        <div class="input-field col s12 input-pad" >
                                         
                                        

                                          <quill-editor
                                            class="editor"
                                            ref="editItemEditor"
                                            :value="item.content"
                                            :options="editorOption"
                                            v-model="item.content"
                                            @change="onEditorChange"
                                            @blur="onEditorBlur($event)"
                                            @focus="onEditorFocus($event)"
                                            @ready="onEditorReady($event)"
                                          />
                                                           
                                            <transition name="fade-slide">
                                              <span v-if="error" class="center-align red-text">{{error}}</span><br>
                                            </transition>
                                          <button @click="cancel();" class="btn grey left">Anuluj</button>
                                          <button @click="save(item);" class="btn right">Zapisz</button>
                                        </div>
                                    </div>
                                    <div v-else-if="action == 'delete'">
                                          <div class='message grey lighten-4 to_delete' v-html="item.content"></div>
                                          <div class="input-field col s12 input-pad button_area_inlne" >
                                            <span class="center-align grey-text text-darken-2">Potwierdź usunięcie</span><br>
                                            <button @click="cancel();" class="btn grey left">Anuluj</button>
                                            <button @click="destroyItem(item);" class="btn red right">Usuń</button>
                                          </div>

                                    </div>
                                  </div>
                              </div>
                          </transition-group>
                        </draggable>
                      </div>
            </div>
            <div v-else>
            <div>
              <span class="grey-text text-darken-2"> Brak wiadomości</span><br>
              <i class="large material-icons grey-text text-lighten-2">chat</i>
              
            </div>
          </div>

            <div v-if="this.action == 'new'">
                <div class="input-field col s12 input-pad" >
                   <quill-editor
                    class="editor"
                    ref="newItemEditor"
                    :value="item.content"
                    :options="editorOption"
                    v-model="item.content"
                    @change="onEditorChange"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                  />
                    <transition name="fade-slide">
                      <span v-if="this.error" class="center-align red-text">{{this.error}}</span><br>
                    </transition>
                  <button @click="cancel();" class="btn grey left">Anuluj</button>
                  <button @click="save(item);" class="btn right">Zapisz</button>
                </div>
            </div>
            <div v-else>
              <div class="button_area_inline">
                 <a @click.stop.prevent="newItem();" class="btn-floating waves-effect waves-light"
                 v-bind:class="{'pulse' : !this.items.length }">
                  <i class="material-icons">add</i></a>
              </div>  
            </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
 import { securedAxiosInstance} from '../backend/axios/index.js';
 import draggable from 'vuedraggable';
 import { quillEditor } from 'vue-quill-editor'
 import 'quill/dist/quill.core.css'
 import 'quill/dist/quill.snow.css'

 //import 'quill/dist/quill.core.css';
 //import 'quill/dist/quill.snow.css';
 //import { quillEditor } from 'vue-quill-editor';
 import { VueEditor } from "vue2-editor";


 export default {
   name: 'items',
   display: "Simple",
  order: 0,
   components: {
     draggable,
     quillEditor,
   },
   props: {
    itemsProp: Array,
    activeSequence: Object,
    },

   data: function(){
          return {
    loading: false,
    action: null,
    edit_id: null,
    items: this.itemsProp,
    item: {
      id: null,
      content: null,
      sequence_id: null,
      item_ord: null,
      },
    originalItem: {},
    enabled: true,
    drag: false,
    orders: [],
    error: null,
    editorOption: {
          modules: {
            toolbar: [
              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['link'],
              ['clean']
            ],
            //syntax: {
              //highlight: text => hljs.highlightAuto(text).value
            //}
          }
    }
   }
 },

   created: function () {
     //this.fetchSequences();
     //this.items = items;
     //this.setItems();
     this.setItems();
   },
   beforeMount() {
    //this.setItems();
      
  },

  mounted() {
      //console.log('this is current quill instance object', this.editor)
      //var blank_input = document.querySelector('.ql-blank');
      //blank_input.dataset.placeholder = "";
    },
  beforeUpdate() {
    //this.setItems();
  },
  updated(){
   //setTimeout(()=>{
   this.setItems();
   console.log('updt');
//},1000);
  },

   methods: {
          setItems() {
            this.items = this.itemsProp;
          },
          newItem() {
          //this.newMessage = true;
          this.action = "new";
          this.item = {
              content: "",
              item_ord: this.items.length + 1,
            }
          },
          editItem(item) {
            this.action = "edit";
            console.log('edit');
            console.log(this.item.id);
            this.edit_id = item.id;
            this.originalItem = Object.assign({}, item);
            this.item = Object.assign({}, item);
            this.error = null;
            //this.newMessage = false;
            //this.confirmDestroy = false;
            //this.deletedIndex = -1;
          },
          validation(content) {
            console.log('content:');
            console.log(content);
            if (content && /([^\s]+)/.test(content.trim())) {
              this.error = null;
              console.log('sth');
            } else {
              this.error = "Treść wiadomości jest wymagana";
              console.log('null');
            }
          },
          save(item) {
            this.validation(item.content);
            if (this.error == null) {
              if (this.action == 'edit') {
                securedAxiosInstance
                  .put(`messages/${item.id}`, {
                    message: {
                      id: item.id,
                      content: item.content, 
                    },
                  })
                  .then(response => {
                   console.log(response);
                   this.reloadSequence();
                   this.action = null;
                   this.edit_id = null;
                  })
                  .catch(error => {
                   console.log(error);
                });
              } else {
                 securedAxiosInstance
                  .post(`sequences/${this.activeSequence.id}/messages/`, {
                     message:  {id: item.id,
                     content: item.content ,
                     sequence_id: this.activeSequence.id,
                     item_ord: item.item_ord,
                     },
                   })
                   .then(response => {
                     //console.log(response);
                     console.log("Created!");
                     this.reloadSequence();
                     this.items.push({}, item);
                     //this.initialize();
                     //this.reloadSequence(); //for rerender sequences
                     this.action = null;
                     this.edit_id = null; 

                     
                   })
                   .catch(error => {
                     console.log(error);
                 });

              }
              //this.close();
              //this.editedIndex = -1;
              //this.item = Object.assign({}, null);
              
              //this.newMessage = false;
              //this.action = "message";
            }
          },
          updateOrders() {
            //const index = this.items.indexOf(item);
            //confirm("Are you sure you want to delete this item?");
            //console.log('destroy');
            //console.log(index) ;


            securedAxiosInstance
              .put(`sequences/${this.activeSequence.id}/messages/update_orders`, {
                sequence_id: this.activeSequence.id,
                items: this.orders,
                 })
              .then(response => {
                console.log(response);
                console.log(response.data.json);
                this.reloadSequence();
                this.action = null;
                this.edit_id = null; 
                //alert(response.data.json);
                //this.initialize();
                //this.updateSequences(); //for rerender sequences
              })
              .catch(error => {
                console.log(error);
              });
              //console.log(items) ;
              //this.items.splice(index, 1);
              //console.log(items) ;
          

        },
          deleteItem(item) {
            this.action = "delete";
            //console.log('delete');
            this.edit_id = item.id;
            //this.originalItem = Object.assign({}, item);
            //this.item = Object.assign({}, item);
          },
          destroyItem(item) {
            const index = this.items.indexOf(item);
            //confirm("Are you sure you want to delete this item?");
            console.log('destroy');
            console.log(index) ;
            securedAxiosInstance
              .delete(`messages/${item.id}`)
              .then(response => {
                console.log(response);
                console.log(response.data.json);
                this.reloadSequence();
                this.action = null;
                this.edit_id = null; 
                this.sort();
                //alert(response.data.json);
                //this.initialize();
                //this.updateSequences(); //for rerender sequences
              })
              .catch(error => {
                console.log(error);
              });
              //console.log(items) ;
              
              //console.log(items) ;
          },
          cancel() {
            //this.editedIndex = -1;
            
            //this.item = Object.assign({}, this.originalItem);
            if (this.action == 'edit') {
              this.items.find( el => el.id === this.edit_id).content = this.originalItem.content;
            }
            //this.items = Object.assign({}, this.originalItem);
            //this.newMessage = false;
            //this.confirmDestroy = false;
            //this.deletedIndex = -1;
            //this.action = "message";
            //this.editedSubsequenceIndex = -1;
            //this.editedSubsequence = Object.assign({}, null);
            this.action = null;
            this.edit_id = null;
            this.error = null;
          },

          reloadSequence() {
            this.$emit('updateSequence', true);
          },
          checkMove: function(e) {
             
             let orders_arr = [];
             window.console.log("Future index: " + e.draggedContext.futureIndex);
             window.console.log("Future index: " + e.draggedContext.element.id);
             //let order_item = {id: e.draggedContext.element.id, index: e.draggedContext.futureIndex }
             //orders_arr.push(order_item);
             //this.orders = orders_arr;
              //console.log(order_item);
             //console.log(orders_arr);
             //console.log(e.relatedContext.component._props.value);
          },

         sort() {
              this.items = this.items.sort((a, b) => a.order - b.order);
            },
          getOrders: function() {
            let itemsArr = (JSON.parse(JSON.stringify(this.$refs.draggable.realList)));
            const itemsOrders = itemsArr.map((item, index) => {
                const container = {};

                container.id = item.id;
                container.item_ord = index + 1; //re-sort index

                return container;
            });
            console.log(itemsArr);
            console.log(itemsOrders);
            this.orders = itemsOrders;
            this.updateOrders();
          },
      onEditorChange(value) {
        //console.log('editor blur!', editor)
        this.item.content = value.html;
      },
      onEditorBlur(editor) {
        console.log('editor blur!', editor);
      },
      onEditorFocus(editor) {
        console.log('editor focus!', editor);
      },
      onEditorReady(editor) {
        console.log('editor ready!', editor);
      }
      




    },
    computed: {
    draggingInfo() {
      return this.drag ? "under drag" : "";
    },
    dragOptions() {
      return {
        animation: 200,
        //group: "description",
        disabled: false,
        //ghostClass: "ghost"
      };
    },
    //editor() {
    //    return this.$refs.myQuillEditor.quill
    //  },
     
  },
  watch: {
    // whenever question changes, this function will run
    //items: function (newQuestion, oldQuestion) {
      //this.answer = 'Waiting for you to stop typing...'
      //this.debouncedGetAnswer()
      //alert('new');
    //}
  },
  

}


 </script>

 <style>



textarea.message {
    width: 100%;
    clear: both !important;
    float: none !important;
    height: 3rem !important;
    padding: 10px !important;
    line-height: 1.4rem;
    margin: 0 !important;
    box-sizing: border-box;
    display: inline-block;
    height: 1.4rem;
    font-size: 16px;
    min-height: 3rem !important
}

textarea.materialize-textarea:focus:not([readonly]) {
    border: 2px solid #0277bd;
}
.input-pad {
  padding: 10px;
  margin-top: 0;
}

.input-field {
  margin-bottom: 34px !important;
}


.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}


.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.hidden-ghost {
  //opacity: 1;
  //visibility: hidden;
  //position: absolute;
  //top: 0;
  //left: 0;
  //visibility: hidden;
  //display:  none;
  background-color: transparent;
}

strong {
    font-weight: bolder !important;
}

.message p {
  margin: 0 !important;
}

.message a {
  border: 2px solid #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
  display: inline-block;
  border-radius: 5px;
  //color: #333;
  position: relative;
  top: 1px;
  cursor: pointer;
  white-space: normal;
  max-width: 300px;
  width: 100%;
  //transition: all ease-in-out 100ms;
  background-color: #0277bd;
  color: white;
}

.message ul {
    list-style-type: disc !important;
    padding-left:  40px !important;
}

.message ul > li {
   list-style-type: none !important;
   display: list-item;
}

.message .ql-align-center {
  text-align: center !important;
}

.message .ql-align-left {
  text-align: left !important;
}

.message .ql-align-right {
  text-align: right !important;
}

.message .ql-align-justify {
  text-align: justify !important;
}

div .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Zapisz' !important;
  }

div .ql-tooltip::before {
    content:  'Adres URL:' !important;
}

div .ql-tooltip a.ql-action::after {
  content: 'Edytuj' !important;
}


div .ql-tooltip a.ql-remove::before {
  content:  'Usuń' !important;
}


.message ol li,  .message ul li {
    padding-left: 0 !important;
    }

.message .ql-indent-1 {
    padding-left: 3em !important;
}
.message .ql-indent-2 {
    padding-left: 6em !important;
}
.message .ql-indent-3 {
    padding-left: 9em !important;
}
.message .ql-indent-4 {
    padding-left: 12em !important;
}

.message ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }


.message ol li:before {
    content: counter(list-0, decimal) '. ';
}

.message ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ' !important;
}

.message li::before {
    margin-left: -1.5em !important;
    margin-right: 0.3em !important;
    text-align: right !important;
}

.message ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
}
.message ol li.ql-indent-1 {
    counter-increment: list-1 !important;
}

.message ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
}
.message ol li.ql-indent-2 {
    counter-increment: list-2 !important;
}

.message ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. '!important;
}

.message ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9 !important;
}

.message ol li.ql-indent-3 {
    counter-increment: list-3 !important;
}

.message ol > li, ul > li {
    list-style-type: none !important;
}

.message ul > li::before {
    content: '\2022' !important;
}

.message li::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
}

.message .ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0em;
}

.message .ql-editor ul > li::before {
    content: '\2022' !important;
}

.ql-blank::before {
  content: 'Treść wiadomości...' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: 'Normalny' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: 'Mały' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: 'Duży' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: 'Bardzo duży' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: 'Normalny' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: 'Nagłówek 1' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: 'Nagłówek 2' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: 'Nagłówek 3' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: 'Nagłówek 4' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: 'Nagłówek 5' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: 'Nagłówek 6' !important;
}
.ql-snow .ql-picker-label {
  padding-right: 8px !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: -4px !important;
}

.message h1 {
  font-size: 2em;
  margin: 0;
}
.message h2 {  
  font-size: 1.5em;
  margin: 0;
}
.message h3 {  
  font-size: 1.17em;
  margin: 0;
}
.message h4 {
  font-size: 1em;
  margin: 0;
}
.message h5 {
  font-size: 0.83em;
  margin: 0;
}
.message h6 {
  font-size: 0.67em;
  margin: 0;
}

 </style>






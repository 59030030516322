<template>
      <transition name="slide-right">
        <div v-if="isOpen" class="modalSequence z-depth-5">
          <div v-show="loading">
                  <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
            
            <div class="progress margin-top0">
              <div class="indeterminate"></div>
            </div>

          </div>

          <div class="overlay-" @click.self="isOpen = false;">
            <div class="  ">
              <div class="padding20">
                <span>
                  <span class="modal-title">Sekwencja</span>
                  <span v-if="!this.editSequence" class="sequence-active">
                     {{activeSequence.name}}
                  </span>
                  <span v-if="!this.editSequence && !this.starting" @click.stop.prevent="editSequenceName()" class="message-btn- close inline-btn">
                    <i class="material-icons">edit</i>
                  </span>
                  <span v-else-if="this.editSequence && !this.starting">
                    
                    <div class="input-field inline input-field-margin0">
                      <input v-model="activeSequence.name" id="sequence_name" type="text" class="input-sequence-name" autocomplete="off">
                    </div>
                    <span @click.stop.prevent="cancelSequenceName()" class=" close inline-btn">
                      <i class="material-icons">close</i>
                    </span>
                    <span @click.stop.prevent="saveSequenceName()" class=" close inline-btn">
                      <i class="material-icons">check</i>
                    </span>
                  </span>
                  <span v-else-if="this.starting">
                    <span  class="close inline-btn disabled">
                      <i class="material-icons">edit</i>
                    </span>
                    <div class="card-panel panel-inline card-panel-up amber lighten-4 grey-text text-darken-2"><i class="material-icons left orange-text text-lighten-3">info_outline</i>Nazwy tej sekwencji nie można zmienić</div>
                  </span>
                </span>
                <span @click.stop.prevent="close" class="close-modal"><i class="material-icons">close</i></span>
              </div>







                

                
                

                <div v-bind:class="this.starting === true ? 'start_height' : '' " class="scroll padding20">

                                  <div class="row margin0 flex-container">


                
       

             
                  <div class="col s5 left-col grey lighten-2 height-modal-item- hide- pad-bot-20">
                  <div class="center-align pad-8 "><span class="grey-text text-darken-2"><b>Tutaj jesteś</b></span></div>
                  
                  <div v-if="this.activeSequence.parent !== null">
                    <parSeq :parSeq="this.activeSequence"
                            :change-view-to-parent="changeActiveSubsequenceToParent"
                    ></parSeq>
                  </div>

                  <div class="center-align">
                      <div class="sequence-active sequence-active-panel">{{this.activeSequence.name}}</div>
                  </div>

                  <div v-if="activeSequence.subsequences.length">
                    <div class="vertical_line_cont">
                      <div class="vertical_line"></div>
                    </div>
                    <div v-for="subseq in activeSequence.subsequences"
                      :key="subseq.id"  class="sequence-container-"
                    >  <div class="vertical_line_cont">
                          <div class="vertical_line_short"></div>
                       </div>
                      <div class="parent_sequence sequence_hov" @click="changeActiveSubsequenceToChild(subseq.id)">{{subseq.name}}</div>
                    </div>
                  </div>
                  <div v-else class="center-vh-">
                    <div class="center-align">
                      <br>
                          <span class="grey-text text-darken-2"> Brak sekwencji podrzędnych</span><br>
                          <i class="large material-icons grey-text">view_stream</i>
                        </div>
                  </div>
                </div>

                  <div class="col s7 right-col">

                  <items v-bind:itemsProp="this.activeSequence.items"
                         v-bind:activeSequence="this.activeSequence"
                         @updateSequence="updateSequences($emit)"
                  ></items>
                          <div class="vertical_line_cont">
                      <div class="vertical_line"></div>
                    </div>
                  <sequence_items v-bind:sequencesProp="this.activeSequence.subsequences"
                         v-bind:activeSequence="this.activeSequence"
                         @updateSequenceSeq="updateSequencesSeq($emit)"
                         :key="childKey"
                  ></sequence_items>
                </div>

                </div>

                </div>
              </div>
            </div>
          </div>
      
      </transition>
</template>


<script>
  import { securedAxiosInstance, plainAxiosInstance } from '../../backend/axios/index.js'
  import { EventBus } from '../../eventbus';
  import parSeq from "../parent_sequence.vue";
  import items from "../items.vue";
  import sequence_items from "../sequence_items.vue";
   export default{
       name: 'SequenceModal',
       props: {
        openProp: Boolean,
        propActiveSequence: null,
       },

       components: {
        parSeq,
        items,
        sequence_items,
      },

       data: function(){
          return {
            isOpen: false,
            loading: true,
            activeSequence: this.propActiveSequence,
            newMessage: false,
            confirmDestroy: false,
            editSequence: false,
            deletedIndex: -1,
            editedIndex: -1,
            editedItem: {
              content: "",
              sequence_id: ""
            },
            deletedSubsequenceIndex: -1,
            editedSubsequenceIndex: -1,
            editedSubsequence: {
              sequence_id: "",
              name: ""
            },
            action: "message",
            childKey: 'child_sequences',
        };
       },
       computed: {
          formTitle() {
            return this.editedIndex === -1 ? "Dodaj" : "Edytuj";
          },
          formTitleSequence() {
            return this.editedSubsequenceIndex === -1 ? "Dodaj" : "Edytuj";
          },
          starting() {
            return this.activeSequence.starting
          }
        },
        mounted() {
          EventBus.$on('change-view-to-parent', (parent_id) => {
           console.log(parent_id);
           this.changeActiveSubsequenceToParent(parent_id);
          });
          if (this.propActiveSequence !== null) {
            this.open();
          } else {
            this.close();
          }
        },
        updated() {
          //this.activeSequence = this.propActiveSequence;
          //console.log('updt-SM');
        },
       methods: {
        open: function()
        {
          console.log('open')
          this.isOpen = true;
          this.activeSequence = this.propActiveSequence; 
          this.initialize();
        },
        close: function()
        {
          console.log('close')
          this.isOpen = false; 
          this.editedIndex = -1;
          this.editedItem = {
              content: "",
            }
          this.$emit('close-modal', false);

        },
        initialize() {
        console.log('initialize SM start');
        this.loading = true;
        return securedAxiosInstance
          .get(`sequences/${this.activeSequence.id}`)
          .then(response => {
             //console.log(response.data);
             this.activeSequence = response.data.data;
             console.log(this.activeSequence);
             //this.newMessage = false;
             //this.confirmDestroy = false;
             //this.deletedIndex = -1;
             //this.editSequence = false;
             //this.editedSubsequenceIndex = -1;
             //this.deletedSubsequenceIndex = -1;
             //this.action = "message";
             console.log("initialize SM end");
             this.loading = false;
           })
          .catch(e => {
             console.log(e);
          });
          
        },

      
       editSequenceName() {
          console.log('edit');
          this.editSequence = true;
          
          var input = document.getElementById("sequence_name");
          console.log('inpt---------------------');
          console.log(input);

                  
        },
       cancelSequenceName() {
          console.log('cancel');
          this.activeSequence.name = this.propActiveSequence.name;
          this.editSequence = false;
                    
        },
        saveSequenceName() {
          securedAxiosInstance
              .put(`sequences/${this.activeSequence.id}`, {
                sequence: {
                  id: this.activeSequence.id,
                  name: this.activeSequence.name 
                },
              })
              .then(response => {
               console.log(response);
               //this.initialize();
               this.editSequence = false;
               this.updateSequences();
              })
              .catch(error => {
               console.log(error);
            });
        },
        updateSequence() {
          this.initialize();
          this.$emit('fetchSequencesTree', true);
        },

        updateSequences() {
          this.initialize();
          this.$emit('fetchSequencesTree', true);
          console.log('emit');
        },
        updateSequencesSeq() {
          this.initialize();
          this.$emit('fetchSequencesTree', true);
          console.log('emit-seq');
        },
        
        changeActiveSubsequenceToChild(subseq_id) {
          console.log(subseq_id);
          //console.log(this.activeSequence.subsequences);
          this.activeSequence.id = subseq_id; 
          EventBus.$emit('sequence-show-upd', subseq_id);
          //this.close();
          //this.loading = false;
          this.initialize();
        },
        changeActiveSubsequenceToParent(parent_id) {
          console.log('hello from parent');
          this.activeSequence.id = parent_id; 
          EventBus.$emit('sequence-show-upd', parent_id);
          //this.close();
          //this.loading = false;
          this.initialize();
        },
        reloadSequence() {
          this.loading = true;
          this.initialize();
          this.updateSequences(); //for rerender sequences
          this.loading = false;
        },
        
      },
       watch: {
          openProp () {
            if (this.openProp) this.open();
            else this.close();
          },
        },
      
   }
</script>

<style scoped>
  .modalSequence {
  width: 30%;
  min-width: 550px;
  height: 100%;
  margin: 0px auto;
  //padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  //transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
  //z-index:9999;
  display: block;
  //opacity:0.7;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  //background: lightblue;
  //min-height: calc(100vh - 100px - 2em);
  //overflow-y: auto;
  overflow-x: hidden;
  z-index: 9998;
  overflow: hidden;
}

.close-modal {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  //background-color: gray;
  display: block;
  border: 2px solid #ccc;
  transition: all 300ms ease-in ;
  cursor: pointer;
  //float: right;
  position: absolute;
  top: 20px;
  right:  20px;
}

.close-modal .material-icons {
margin-top: 3px;
}

.close-modal:hover {
  background-color: #ccc;  
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


  .overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //width: 100%;
  //height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 9999;
  //transition: opacity 0.2s ease;
}



.sequence-active {
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  top: -5px;
  background: #0d47a1;
  color: #fff;
  //border: 1px solid #ccc;
}

.modal-title {
  font-size: 2em;
}

.padding20 {
  padding: 20px;
}

.message-container {
  width: 300px;
  margin: 0 auto;
  display:flex;
        flex-direction: column;
}

.message {
  border-radius: 10px;
  padding: 10px;
  color: black;
  float:left;
  clear:both;
  margin: 10px;
  margin-top: 0px;
  position:relative;
  transition: all .3s ease;
  text-align:left;
  min-width:100px;
}

.message:hover {
  color: #424242;
}

.message-overlay {
  position:absolute;
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(20, 20, 20, 0.8);
  width:100%;
  height:100%;
  border-radius: 10px;
  display:none;
  justify-content: center;
  align-items: center;
}

.message:hover .message-overlay {
  display:flex;
  transition: all .3s ease;
}

.message-btn {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  display: block;
  border: none;
  transition: all 300ms ease-in ;
  cursor: pointer;
  float: right;
  background-color: #757575; 
  transition: all .3s ease;
  color: #c5cae9; 
}

.message-btn .material-icons {
margin-top: 3px;
color: #c5cae9;
}

.message-btn:hover {
  background-color: #0277bd; 

}

.message-btn.delete:hover  {
  background-color: #F44336; 

}

.message-btn:hover .material-icons {
  color: white;
}

.margin0 {
  margin:0;
}

.message.active {
  background-color: #0277bd !important;
  color: white;
}

.message.to_delete {
  background-color: rgb(244, 67, 54) !important;
  color: white;
}

.scroll {
  overflow-y: auto;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 100px);
  height:  auto;
  //max-height: 100vh;
  //height: calc(100% - 4em - 87px);
  //max-height: calc(100vh - 4em - 200px);
  //margin-left:10px;
}

.scrollable:hover {
  //overflow-y: auto;
  margin-left:0px;
}
.center-vh {
  position: relative;
  height: calc(100vh - 2em - 190px);
}
.center-vh > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  right: 0;
  left: 0;
  max-height: calc(100vh - 4em - 200px);
}

.height-modal-item {
    height: calc(100vh - 2em - 190px);
}

.center-plus-btn {
  margin: 17px 0px;
}

.inline-btn {
  display: inline-block !important;
  float:none;
}

.input-sequence-name {
    padding: 0.5em 0.75em  !important;
    text-decoration: none !important;
    display: inline-block !important;
    border-radius: 5px !important;
    position: relative !important;
    top: -5px !important;
    background: #0d47a1 !important;
    color: #fff !important;
    //border: 1px solid #e9453f !important;
    height: 22px !important;
    width: 200px !important;
    top: 0 !important;
}

.input-sequence-name:focus {
  box-shadow: none !important;
}

.input-field {
  margin-bottom: 30px !important;
}

.input-field-margin0 {
  margin-bottom: 0;
  margin-top: 0;
}

.disabled {
  cursor: not-allowed;
  color: #bdbdbd;
  background-color: #eeeeee ;
  border-color: #eeeeee;
}

.disabled:hover {
  background-color: #eeeeee ;
  border-color: #eeeeee;
}
.sequence-container {
  //width: 200px;
  margin: 0 auto;
  display:flex;
        flex-direction: column;
        justify-content: center;
}

.sequence {
  border-radius: 10px;
  padding: 10px;
  color: white;
  float:left;
  clear:both;
  margin: 10px;
  margin-top: 0px;
  position:relative;
  transition: all .3s ease;
  text-align:left;
  min-width:100px;
  background-color: #0d47a1;
  transition: ease all 300ms;
}

.sequence::first {
  margin-top: 10px;
}

.sequence:hover {
  background-color: #04152f;
}

.sequence-overlay {
  position:absolute;
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(20, 20, 20, 0.8);
  width:100%;
  height:100%;
  border-radius: 10px;
  display:none;
  justify-content: center;
  align-items: center;
}

.sequence:hover .sequence-overlay {
  display:flex;
  transition: all .3s ease;
}

.sequence-btn {
  width: 32px;
  height:32px;
  text-align: center;
  border-radius:50%;
  display: block;
  border: none;
  transition: all 300ms ease-in ;
  cursor: pointer;
  float: right;
  background-color: #757575; 
  transition: all .3s ease;
  color: #c5cae9; 
}

.sequence-btn .material-icons {
margin-top: 3px;
color: #c5cae9;
}

.sequence-btn:hover {
  background-color: #0277bd; 

}

.sequence-btn.delete:hover  {
  background-color: #F44336; 

}

.sequence-btn:hover .material-icons {
  color: white;
}

.vertical_line_cont {
  //position: relative;
 
}
.vertical_line {
  border-left: 2px solid #ccc;
  height: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width:0;
}

.vertical_line_short {
  border-left: 2px solid #ccc;
  height: 8px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width:0;
}

.container_border {
  margin:0;
  border-radius: 4px;
  border: 2px solid #ccc;
  padding: 10px;
  position:relative;
}

.parent_sequence {
  align-self: flex-start;
  //border: 2px solid #ccc;
  background-color: #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-flex;
  border-radius: 5px;
  color: #333;
  //position: relative;
  //top: 1px;
  cursor: default;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

input.border-box {
  box-sizing: border-box !important;
}

.pad30 {
  padding:30px;
}


.panel-inline {
  display: inline-block;
}

.card-panel {
  padding: 6px 12px;
  margin:0;
  border-radius:12px;
  box-shadow: none;
}
.card-panel i {
  margin-right:5px;
}

.card-panel-up {
  position: relative;
  top: -8px;
}

.sequence-active-panel {
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  background: #0d47a1;;
  color: #fff;
  //border: 1px solid #e9453f;
  margin: 0 auto;
  position: static !important;
}

.pad-8 {
padding: 8px;
}

.static {
  position: static !important;
  transform: none !important;
}

.sequence_hov {
  transition: all 300ms ease;
  cursor: pointer;
}

.sequence_hov:hover {
      background: #0d47a1;
    color: #fff;

}

.button_area_inlne {
  clear:  both;
}

.slide-right-enter-active {
  transition: all .3s ease-out;
}

.slide-right-leave-active {
  transition: all .3s ease-out;
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
  right: 0;
}

.margin-top0 {
  margin-top: 0;
}

.start_height{
  max-height: calc(100vh - 120px) !important;
}

.flex-container {
  display: flex;
}

.right-col { 
  padding: 0 !important;
}

.left-col {
  padding-bottom: 10px !important;
  margin-right: 10px !important;
}
</style>